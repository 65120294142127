import { useTopNavRouteData } from 'components/appRoute/hooks/useTopNavRouteData';
import useUserPermission from 'hooks/useUserPermission';
import PropTypes from 'prop-types';
import { createContext, useMemo } from 'react';

export const PermissionsContext = createContext(null);

export function PermissionsProvider({ children }) {
    const { featureName } = useTopNavRouteData();
    const permissions = useUserPermission(featureName);

    const value = useMemo(() => ({ permissions }), [permissions]);

    return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
}

PermissionsProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
