import keyBy from 'lodash/keyBy';
import sortedIndexBy from 'lodash/sortedIndexBy';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectAuthoriziedItemList } from 'store/companyAuthorizedList/companyAuthorizationListSelectors';
import { companyAuthorizationListActions } from 'store/companyAuthorizedList/companyAuthorizationListSlice';
import { selectMasterList } from 'store/organizationList/organizationListSelectors';
import { organizationListActions } from 'store/organizationList/organizationListSlice';
import { peopleAuthorizedListActions } from 'store/peopleAuthorizedList/peopleAuthorizedListSlice';
import * as authorizedPeopleSelectors from 'store/peopleAuthorizedList/peopleAuthorizedListsSelectors';
import { INDUSTRY_OPTIONS, RELATION_OPTIONS } from 'utils/constants';
import { ACCESS_POINT_RECORDS_TABLE_ID } from '../constants/accessPointRecordTableConstant';
import {
    selectAccessPointRecordsColumnList,
    selectAccessPointRecordsDataByTableId,
    selectAccessPointRecordsFilterDateRange,
    selectAllFilter,
    selectFetchingAccessPointRecordsColumnError,
    selectFetchingAccessPointRecordsColumnInProgress,
    selectFetchingAccessPointRecordsError,
    selectFetchingAccessPointRecordsInProgress,
    selectRemovingAccessPointRecordError,
    selectRemovingAccessPointRecordInProgress,
    selectSearchText,
    selectSelectedFilter,
} from '../store/accessPointRecordsSelectors';
import {
    fetchAccessPointRecordsColumnStart,
    fetchAccessPointRecordsStart,
    removeAccessPointRecordsStart,
    setAllFilter,
    setFilterDateRange,
    setSearchText,
    setSelectedFilter,
} from '../store/accessPointRecordsSlice';

const useAccessPointRecord = () => {
    const dispatch = useDispatch();

    const dispatchFetchAccessPointRecords = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsStart({ locationId }));
            dispatch(companyAuthorizationListActions.fetchAuthorizedListPerLocation({ id: locationId }));
            dispatch(peopleAuthorizedListActions.fetchAuthorizedPersonPerLocation({ id: locationId }));
        },
        [dispatch]
    );

    const dispatchFetchAccessPointRecordsColumn = useCallback(
        (locationId) => {
            dispatch(fetchAccessPointRecordsColumnStart({ locationId }));
        },
        [dispatch]
    );

    const dispatchOrganizationList = useCallback(() => {
        dispatch(organizationListActions.fetchMasterListStart());
    }, [dispatch]);

    const dispatchSetDateRange = useCallback(
        (dateRange) => {
            dispatch(
                setFilterDateRange({
                    startDate: dateRange.startDate?.toISOString(),
                    endDate: dateRange.endDate?.toISOString(),
                })
            );
        },
        [dispatch]
    );

    const dispatchSetSearchText = useCallback(
        (text) => {
            dispatch(setSearchText(text));
        },
        [dispatch]
    );

    const dispatchSetSelectedFilter = useCallback(
        (filter) => {
            dispatch(setSelectedFilter(filter));
        },
        [dispatch]
    );
    const dispatchSetAllFilter = useCallback(
        (filters) => {
            dispatch(setAllFilter(filters));
        },
        [dispatch]
    );

    const dispatchRemoveAccessPointRecord = useCallback(
        (locationId) => {
            dispatch(removeAccessPointRecordsStart({ locationId }));
        },
        [dispatch]
    );

    const fetchingAccessPointRecordsInProgress = useSelector(selectFetchingAccessPointRecordsInProgress);
    const fetchingAccessPointRecordsError = useSelector(selectFetchingAccessPointRecordsError);

    const fetchingAccessPointRecordsColumnInProgress = useSelector(selectFetchingAccessPointRecordsColumnInProgress);
    const fetchingAccessPointRecordsColumnError = useSelector(selectFetchingAccessPointRecordsColumnError);

    const accessPointRecordsColumnList = useSelector(selectAccessPointRecordsColumnList);
    const selectAccessPointRecordsSelector = useMemo(() => selectAccessPointRecordsDataByTableId(ACCESS_POINT_RECORDS_TABLE_ID), []);
    const { tableData } = useSelector(selectAccessPointRecordsSelector);
    const filterDateRange = useSelector(selectAccessPointRecordsFilterDateRange);
    const searchText = useSelector(selectSearchText);
    const selectedFilter = useSelector(selectSelectedFilter);
    const allFilter = useSelector(selectAllFilter);

    const removingAccessPointRecordInProgress = useSelector(selectRemovingAccessPointRecordInProgress);
    const removingAccessPointRecordError = useSelector(selectRemovingAccessPointRecordError);

    const organizationList = useSelector(selectMasterList);
    const authorizedItems = useSelector(selectAuthoriziedItemList);
    const peopleList = useSelector(authorizedPeopleSelectors.selectAuthoriziedPeopleList);

    const authOrganizationList = useMemo(() => {
        if (!organizationList) return [];
        // Create an object keyed by authorizedOrganization.id
        const authorizedItemsObject = keyBy(authorizedItems, (item) => item.authorizedOrganization.id);

        // Create objects keyed by relationType and industryType
        const relationTypeObject = keyBy(RELATION_OPTIONS, 'value');
        const industryTypeObject = keyBy(INDUSTRY_OPTIONS, 'value');

        return organizationList.reduce((result, organization) => {
            const authOrganization = authorizedItemsObject[organization.id];

            if (authOrganization && authOrganization.authTypes?.length > 0) {
                const newOrg = {
                    name: organization.name,
                    authTypes: authOrganization.authTypes,
                    address: authOrganization?.address,
                    relationType: relationTypeObject[authOrganization?.relationType]?.label,
                    industryType: industryTypeObject[authOrganization?.industryType]?.label,
                    monitoringObjectIds: authOrganization?.monitoringObjectIds,
                };

                const insertIndex = sortedIndexBy(result, newOrg, (org) => -org.authTypes.length);
                result.splice(insertIndex, 0, newOrg);
            }

            return result;
        }, []);
    }, [authorizedItems, organizationList]);

    const authPeopleList = useMemo(() => {
        if (!peopleList) return [];

        return peopleList.reduce((result, person) => {
            if (person.authTypes?.length > 0) {
                const newPerson = {
                    name: `${person.firstName} ${person.lastName}`,
                    authTypes: person.authTypes,
                    position: person?.position,
                    dl: person?.dl,
                    cdl: person?.cdl,
                    dateOfBirth: person?.dateOfBirth,
                    companyCardNumber: person?.companyCardNumber,
                    organizationName: person?.organization?.name,
                    monitoringObjectIds: person?.monitoringObjectIds,
                };

                const insertIndex = sortedIndexBy(result, newPerson, (p) => -p.authTypes.length);
                result.splice(insertIndex, 0, newPerson);
            }
            return result;
        }, []);
    }, [peopleList]);

    return {
        fetchAccessPointRecords: dispatchFetchAccessPointRecords,
        fetchAccessPointRecordsColumn: dispatchFetchAccessPointRecordsColumn,
        dispatchSetSearchText,
        dispatchSetSelectedFilter,
        dispatchSetAllFilter,
        fetchingAccessPointRecordsInProgress,
        fetchingAccessPointRecordsError,
        accessPointRecordsList: tableData,
        fetchingAccessPointRecordsColumnInProgress,
        fetchingAccessPointRecordsColumnError,
        accessPointRecordsColumnList,

        filterDateRange,
        setFilterDateRange: dispatchSetDateRange,
        searchText,
        selectedFilter,
        allFilter,
        removeAccessPointRecord: dispatchRemoveAccessPointRecord,
        removingAccessPointRecordInProgress,
        removingAccessPointRecordError,
        dispatchOrganizationList,
        authOrganizationList,
        authPeopleList,
    };
};

export default useAccessPointRecord;
