import { ACCESS_POINT_RECORDS_TABLE_ID } from 'components/accessPointRecords/constants/accessPointRecordTableConstant.js';
import { selectAccessPointRecordsFilterDateRange } from 'components/accessPointRecords/store/accessPointRecordsSelectors.js';
import { selectSelectedLocationId } from 'components/globalLocationSelector/store/globalLocationSelectors.js';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
    fetchAccessPointRecordsByLocation,
    fetchAccessPointRecordsColumnByLocation,
    fetchAccessPointRecordsFormFieldsByLocation,
    removeAccessPointRecordsByLocation,
} from 'services/accessPointRecordService.js';

import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import { applyTransformations, excludeFields, reorderGateBeforeDirection } from 'utils/formFieldHelper';
import notificationsHandler from 'utils/notificationsHandler';
import {
    accessPointRecordsActions,
    fetchAccessPointRecordsColumnError,
    fetchAccessPointRecordsColumnStart,
    fetchAccessPointRecordsColumnSuccess,
    fetchAccessPointRecordsError,
    fetchAccessPointRecordsFormFieldError,
    fetchAccessPointRecordsFormFieldStart,
    fetchAccessPointRecordsFormFieldSuccess,
    fetchAccessPointRecordsStart,
    fetchAccessPointRecordsSuccess,
    removeAccessPointRecordsError,
    removeAccessPointRecordsStart,
    removeAccessPointRecordsSuccess,
    setSearchText as searchTextSlice,
    setFilterDateRange,
} from './accessPointRecordsSlice';

// Worker saga: will be fired on fetchAccessPointRecordsStart actions
function* fetchAccessPointRecords({ payload: { locationId } }) {
    const tableId = ACCESS_POINT_RECORDS_TABLE_ID;
    const append = false;
    const pageNum = 0;

    try {
        const filterDateRange = yield select(selectAccessPointRecordsFilterDateRange);
        const filters = {
            ...filterDateRange,
        };
        const data = yield fetchAccessPointRecordsByLocation(locationId, filters);
        yield put(fetchAccessPointRecordsSuccess({ data, pageNum, tableId, append }));
    } catch (error) {
        yield put(fetchAccessPointRecordsError(error.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        yield call(notificationsHandler, { title: formattedError });
    }
}

// Worker saga: will be fired on removeAccessPointRecordsStart actions
function* removeAccessPointRecords({ payload: { locationId } }) {
    try {
        yield removeAccessPointRecordsByLocation(locationId);
        yield put(removeAccessPointRecordsSuccess(locationId));
        yield notificationsHandler({
            title: 'Successfully removed transaction',
            variant: 'success',
        });
    } catch (error) {
        yield put(removeAccessPointRecordsError(error.message));
        yield call(notificationsHandler, {
            error,
            title: 'Error removing access point record',
        });
    }
}

// Watcher saga: spawns a new fetchAccessPointRecords task on each fetchAccessPointRecordsStart
function* onFetchAccessPointRecordsStart() {
    yield takeLatest(fetchAccessPointRecordsStart.type, fetchAccessPointRecords);
}

// Worker saga: will be fired on fetchAccessPointRecordsColumnStart actions
function* fetchAccessPointRecordsColumn({ payload: { locationId } }) {
    try {
        const data = yield fetchAccessPointRecordsColumnByLocation(locationId);
        yield put(fetchAccessPointRecordsColumnSuccess(data));
    } catch (error) {
        yield put(fetchAccessPointRecordsColumnError(error.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        yield call(notificationsHandler, { title: formattedError });
    }
}

function* onfetchAccessPointRecordsFormFieldStart() {
    yield takeLatest(fetchAccessPointRecordsFormFieldStart.type, fetchAccessPointRecordsFormField);
}
function* fetchAccessPointRecordsFormField({ payload: { locationId } }) {
    try {
        const data = yield fetchAccessPointRecordsFormFieldsByLocation(locationId);
        const transformedFields = applyTransformations(data, excludeFields(['appointment.number']), reorderGateBeforeDirection());
        yield put(fetchAccessPointRecordsFormFieldSuccess(transformedFields));
    } catch (error) {
        yield put(fetchAccessPointRecordsFormFieldError(error.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        yield call(notificationsHandler, { title: formattedError });
    }
}

// Watcher saga: spawns a new fetchAccessPointRecordsColumn task on each fetchAccessPointRecordsColumnStart
function* onFetchAccessPointRecordsColumnStart() {
    yield takeLatest(fetchAccessPointRecordsColumnStart.type, fetchAccessPointRecordsColumn);
}

function* setFilterDateRangeHandle() {
    const locationId = yield select(selectSelectedLocationId);
    yield put(accessPointRecordsActions.fetchAccessPointRecordsStart({ locationId }));
}
function* onFilterDateRange() {
    yield takeLatest(setFilterDateRange.type, setFilterDateRangeHandle);
}

function* setSearchText({ payload: { text } }) {
    if (text) yield put(searchTextSlice(text));
}

function* onSetSearchText() {
    yield takeLatest(searchTextSlice.type, setSearchText);
}

// Watcher saga: spawns a new removeAccessPointRecords task on each removeAccessPointRecordsStart
function* onRemoveAccessPointRecordsStart() {
    yield takeLatest(removeAccessPointRecordsStart.type, removeAccessPointRecords);
}

// Root saga: combines all the watcher sagas
export function* accessPointRecordsSaga() {
    yield all([
        call(onFetchAccessPointRecordsStart),
        call(onFetchAccessPointRecordsColumnStart),
        call(onfetchAccessPointRecordsFormFieldStart),
        call(onSetSearchText),
        call(onFilterDateRange),
        call(onRemoveAccessPointRecordsStart),
    ]);
}
