import { get } from 'lodash';
import { PERMISSION_NAME } from 'utils/constants.js';
import { FEATURE_LEVEL_USER_ROLE_MAPPING } from './featureAndRolePermissions';

export const hasSomePermissionsOnFeature = (user, locationId, featureName, permissionNames) => {
    const featurePermissions = get(user, `userLocationFeature.${locationId}.${featureName}`, []);
    return permissionNames.some((perm) => featurePermissions.includes(perm));
};

export const hasReadPermissionOnFeature = (user, locationId, featureName) => {
    return hasSomePermissionsOnFeature(user, locationId, featureName, [PERMISSION_NAME.VIEW, PERMISSION_NAME.FULL_ACCESS]);
};

export const hasCreatePermissionOnFeature = (user, locationId, featureName) => {
    return hasSomePermissionsOnFeature(user, locationId, featureName, [PERMISSION_NAME.FULL_ACCESS]);
};

export const hasEditPermissionOnFeature = (user, locationId, featureName) => {
    return hasSomePermissionsOnFeature(user, locationId, featureName, [PERMISSION_NAME.FULL_ACCESS]);
};

export const hasDeletePermissionOnFeature = (user, locationId, featureName) => {
    return hasSomePermissionsOnFeature(user, locationId, featureName, [PERMISSION_NAME.FULL_ACCESS]);
};

export const hasAtLeastPermissionForFeature = (user, featureName, features = [], permissionNames) => {
    const userFeaturePermissions = Object.values(get(user, 'userLocationFeature', {}));
    const hasPermission = userFeaturePermissions.some((userFeaturePermission) => {
        const featurePermissions = get(userFeaturePermission, featureName, []);
        if (featurePermissions.length) {
            return permissionNames.some((perm) => featurePermissions.includes(perm));
        }
        return false;
    });

    const featureLevelThatUserHasAccessOn = user?.roles?.map((role) => FEATURE_LEVEL_USER_ROLE_MAPPING[role]).flat();
    const featuresLevelForFeatureName = features?.find((feature) => feature.name === featureName)?.level;
    return hasPermission && featureLevelThatUserHasAccessOn.includes(featuresLevelForFeatureName);
};

export const hasAtLeastReadPermissionForFeature = (user, featureName, features) => {
    return hasAtLeastPermissionForFeature(user, featureName, features, [PERMISSION_NAME.VIEW]);
};

export const hasAtLeastFullAccessPermissionForFeature = (user, featureName, features) => {
    return hasAtLeastPermissionForFeature(user, featureName, features, [PERMISSION_NAME.FULL_ACCESS]);
};
