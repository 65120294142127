import { AddCircleOutlined, ArrowForwardIosRounded, MoreVert } from '@mui/icons-material';
import { Box, Button, List, ListItemText, Stack } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';
import { memo, useState } from 'react';

const InstructionsSection = () => {
    const instructions = [
        {
            key: 'search',
            title: 'Asset Search',
        },
        {
            key: 'switchView',
            title: 'Switch Inventory View',
        },
        {
            key: 'view',
            title: 'View Asset in Inventory',
        },
        {
            key: 'addNew',
            title: 'Adding New Asset to Current Inventory',
        },
        {
            key: 'edit',
            title: 'Edit Asset in Current Inventory',
        },
        {
            key: 'remove',
            title: 'Remove Asset from Current Inventory',
        },
    ];

    const [selectedInstructions, setSelectedInstructions] = useState(instructions[0].key);

    const AssetSearchInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>Asset Search</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>Type keywords into the Search box.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>Press Enter to start searching.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>The Inventory List will narrow down to display only assets that match the entered keywords.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>A message will appear if no matching assets are found in the viewing Inventory List.</span>
                    </ListItemText>
                </List>
            </>
        ),
        []
    );
    AssetSearchInstructions.displayName = 'AssetSearchInstructions';

    const AssetSwitchViewInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>Switch Inventory View</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Click on dropdown to choose between <b>Today</b> and <b>Custom</b>.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Choose <b>Today</b> if you want to view Asset in <b>Current Inventory</b>.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Choose <b>Custom</b> then pick a specific Date and Time to view Asset in <b>Point-In-Time Inventory.</b>
                        </span>
                    </ListItemText>
                </List>
            </>
        ),
        []
    );
    AssetSwitchViewInstructions.displayName = 'AssetSwitchViewInstructions';

    const ViewAssetInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>View Asset in Inventory</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            You can either double click on any Asset or open Action Options from <MoreVert className={styles['inlined-icon']} /> then
                            choose View to open modal popup with full information of selected Asset.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>A modal popup shall display with all available information of selected Asset.</span>
                    </ListItemText>
                </List>
            </>
        ),
        []
    );
    ViewAssetInstructions.displayName = 'ViewAssetInstructions';

    const AddNewAssetInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>Add New Asset</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Click on
                            <AddCircleOutlined className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            icon to open Add New Asset form.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>Selecting an Asset Type shall add more fields to form base on selected Asset Type.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>Fill in required fields and any necessary optional fields before submitting form.</span>
                        <List className={styles['unordered-list']}>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [Save] button to add new Asset.</span>
                            </ListItemText>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [Cancel] button to cancel adding new Asset.</span>
                            </ListItemText>
                        </List>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Lasta will validate value in fields to ensure the required fields are filled and no existing duplication in{' '}
                            <b>Current Inventory.</b>
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        Form shall be closed after submitting and Inventory List shall be refreshed and turned back to <b>Current Inventory</b> to
                        reflect new update.
                    </ListItemText>
                </List>
                <span>
                    <i>
                        * Only privileged users can add new Asset to <b>Current Inventory</b> and new Assets shall be added to{' '}
                        <b>Current Inventory</b> ONLY.
                    </i>
                </span>
            </>
        ),
        []
    );
    AddNewAssetInstructions.displayName = 'AddNewAssetInstructions';

    const EditAssetInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>Edit Asset</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            You can either double click on any Asset or open Action Options from <MoreVert className={styles['inlined-icon']} /> then
                            choose Edit to open modal popup with full information of selected Asset.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>A modal popup shall display with all available information of selected Asset.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>Make updates in form while ensuring required fields are filled before submitting form.</span>
                        <List className={styles['unordered-list']}>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [Save] button to update Asset.</span>
                            </ListItemText>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [Cancel] button to dispose all changes and cancel updating Asset.</span>
                            </ListItemText>
                        </List>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Lasta will validate value in fields to ensure the required fields are filled and no existing duplication in{' '}
                            <b>Current Inventory</b>.
                        </span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Form shall be closed after submitting and Inventory List shall be refreshed and turned back to <b>Current Inventory</b> to
                            reflect new update.
                        </span>
                    </ListItemText>
                </List>
                <span>
                    <i>
                        * Only privileged users can edit existing Asset in <b>Current Inventory</b> and ONLY Assets in <b>Current Inventory</b>{' '}
                        support edit action.
                    </i>
                </span>
            </>
        ),
        []
    );
    EditAssetInstructions.displayName = 'EditAssetInstructions';

    const RemoveAssetInstructions = memo(
        () => (
            <>
                <p className={styles['instruction-header']}>Remove Asset</p>
                <List className={styles['ordered-list']}>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            You can either double click on any Asset or open Action Options from <MoreVert className={styles['inlined-icon']} /> then
                            choose Edit to open modal popup with full information of selected Asset.
                        </span>
                        <List className={styles['unordered-list']}>
                            <ListItemText className={styles['list-item']}>
                                <span>
                                    You can also choose Remove option from <MoreVert className={styles['inlined-icon']} /> of any Asset to remove
                                    Asset.
                                </span>
                            </ListItemText>
                        </List>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>A modal popup shall display with all available information of selected Asset.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>Click on button [Remove] to remove viewing Asset.</span>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>A Prompt Message shall display:</span>
                        <List className={styles['unordered-list']}>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [Yes] button to confirm remove Asset.</span>
                            </ListItemText>
                            <ListItemText className={styles['list-item']}>
                                <span>Click on [No] button to cancel remove action.</span>
                            </ListItemText>
                        </List>
                    </ListItemText>
                    <ListItemText className={styles['list-item']}>
                        <span>
                            Form shall be closed after confirming and Inventory List shall be refreshed and turned back to <b>Current Inventory</b> to
                            reflect new update.
                        </span>
                    </ListItemText>
                </List>
                <span>
                    <i>
                        * Only privileged users can remove existing Asset in <b>Current Inventory</b> and ONLY Assets in <b>Current Inventory</b>{' '}
                        support remove action.
                    </i>
                </span>
            </>
        ),
        []
    );
    RemoveAssetInstructions.displayName = 'RemoveAssetInstructions';

    const getInstructionsComponent = (key) => {
        switch (key) {
            case 'search':
                return <AssetSearchInstructions />;
            case 'switchView':
                return <AssetSwitchViewInstructions />;
            case 'view':
                return <ViewAssetInstructions />;
            case 'addNew':
                return <AddNewAssetInstructions />;
            case 'edit':
                return <EditAssetInstructions />;
            case 'remove':
                return <RemoveAssetInstructions />;
        }
    };
    return (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="24rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstructions(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                          ${selectedInstructions === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRounded className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInstructionsComponent(selectedInstructions)}</Box>
            </Box>
        </>
    );
};

export default InstructionsSection;
