import { call, put, select } from 'redux-saga/effects';
import { selectFormById } from 'store/formControl/formControlSelectors';
import { formControlActions } from 'store/formControl/formControlSlice';
import store from 'store/store';
import notificationsHandler from 'utils/notificationsHandler';
import { axiosLasta } from 'config';
import { monitoringObject } from 'features/monitoringObject/formConfig';
import { FORM_STATUS } from 'features/monitoringObject/constants';

const formId = monitoringObject.locationListForm.id;

function* isFormValid(errors) {
    let isFormValid = true;
    Object.keys(errors).forEach((fieldName) => {
        if (errors[fieldName]) {
            isFormValid = false;
            store.dispatch(formControlActions.setFieldTouched({ formId, fieldName }));
        }
    });
    if (!isFormValid) {
        yield notificationsHandler({
            title: 'Please fill required fields',
            variant: 'error',
        });
    }

    return isFormValid;
}
function* hasDataChanged(beforeEditData, values) {
    const hasChanged = Object.keys(values).reduce((hasChanged, fieldName) => {
        let setHasChanged = false;
        const newValue = values[fieldName];
        const oldValue = beforeEditData[fieldName];
        const isBoolean = typeof oldValue === 'boolean';
        const comparationValue = isBoolean ? oldValue : oldValue || null;
        if (newValue !== comparationValue) setHasChanged = true;
        return hasChanged || setHasChanged;
    }, false);

    if (!hasChanged) {
        yield call(notificationsHandler, {
            title: 'No changes detected',
            variant: 'info',
        });
    }
    return hasChanged;
}

function* addNewMonitoringObject(values, location, url) {
    const payload = { ...values, location };
    for (const property in payload) payload[property] === '' && (payload[property] = null);
    const response = yield call(axiosLasta.post, url, payload);
    const { message } = response.data;
    return message;
}

function* editMonitoringObject(beforeEditData, values, monitoringObjectId, url) {
    const hasChanged = yield call(hasDataChanged, beforeEditData, values);
    if (!hasChanged) return;
    const { location, ...cleanedValues } = values;
    const payload = { ...cleanedValues, id: monitoringObjectId };
    const response = yield call(axiosLasta.put, url, payload);
    const { message } = response.data;
    return message;
}

function* handleMessage(message, type) {
    if (!message) return;
    if (message.includes('already exist')) {
        yield put(
            formControlActions.setFieldError({
                formId,
                fieldName: type.fields.name.fieldName,
                error: `${type.formFor} already exists`,
            })
        );
        return;
    }
    if (message.includes('[CONFLICT]')) {
        yield notificationsHandler({
            title: `This Monitoring Object is still assigned to active records. Please reassign or remove it from those records before setting it to inactive.`,
            variant: 'warning',
        });
        return;
    }
    if (message.includes('[SUCCESS]')) {
        yield put(type.actions.fetchTableValues(type.locationId));
        yield put(type.actions.setDialogOpen(false));
        yield notificationsHandler({
            title: `SUCCESSFULLY UPDATED`,
            variant: 'success',
        });
    }
}

export function* submitForm({ payload: config }) {
    try {
        const { form } = yield select(selectFormById(formId));
        const { values, errors } = form || {};

        const isValid = yield call(isFormValid, errors);
        if (!isValid) return;

        let message;
        if (config.formStatus === FORM_STATUS.ADD) message = yield call(addNewMonitoringObject, values, config.locationId, config.postUrl);
        if (config.formStatus === FORM_STATUS.EDIT)
            message = yield call(editMonitoringObject, config.selectedMonitoringObject, values, config.id, config.putUrl);

        yield call(handleMessage, message, config);
    } catch (err) {
        yield put(config.actions.submitFormFailed(JSON.stringify(err.message)));
    }
}
