import { createSlice } from '@reduxjs/toolkit';
import { AUDIO_CONFIGURATION_DIALOG_MODE } from '../constants';

const initialState = {
    httpRequest: {
        inProgress: false,
        error: false,
        type: '',
    },
    dialog: { open: false, mode: AUDIO_CONFIGURATION_DIALOG_MODE.NEW },

    audioTypes: [],

    //table
    audioConfiguredCameras: [],
    filterCriteria: { text: '', audioType: {} },
    filteredCameras: [],

    //dialog dropdown options
    allLocations: [],
    monitoringObjects: [],
    monitoringObjectCameras: [],

    //dialog values
    selectedLocation: null,
    selectedMonitoringObject: null,
    selectedCamera: null,
    selectedAudioType: '',
    extensionNumber: undefined,
    errorMessage: '',
};

const audioConfigurationSlice = createSlice({
    name: 'audioConfiguration',
    initialState,
    reducers: {
        requestStart(state, { payload }) {
            state.httpRequest.inProgress = true;
            state.httpRequest.error = false;
            state.httpRequest.type = payload;
        },
        requestError(state) {
            state.httpRequest.inProgress = false;
            state.httpRequest.error = true;
            state.httpRequest.type = '';
        },
        requestSucces(state) {
            state.httpRequest.inProgress = false;
            state.httpRequest.type = '';
        },

        setAllLocations(state, { payload: allLocations }) {
            state.allLocations = allLocations;
        },
        setMonitoringObjects(state, { payload: monitoringObjects }) {
            state.monitoringObjects = monitoringObjects;
        },

        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
            state.selectedMonitoringObject = null;
            state.monitoringObjects = [];
            state.selectedCamera = null;
            state.monitoringObjectCameras = [];
            state.extensionNumber = undefined;
            state.selectedAudioType = '';
        },

        setAudioTypes(state, { payload: audioTypes }) {
            state.audioTypes = audioTypes;
            audioTypes.forEach(({ value }) => {
                const filterValue = state.filterCriteria.audioType[value];
                if (filterValue === undefined) {
                    state.filterCriteria.audioType[value] = true;
                }
            });
        },

        setSelectedMonitoringObject(state, { payload: selectedMonitoringObject }) {
            state.selectedMonitoringObject = selectedMonitoringObject;
            state.selectedCamera = null;
            state.monitoringObjectCameras = [];
            state.extensionNumber = undefined;
            state.selectedAudioType = '';
        },

        setSelectedCamera(state, { payload: selectedCamera }) {
            state.selectedCamera = selectedCamera;
            state.extensionNumber = undefined;
            state.selectedAudioType = '';
        },

        setSelectedAudioType(state, { payload: selectedAudioType }) {
            state.selectedAudioType = selectedAudioType;
            state.extensionNumber = undefined;
        },

        setExtensionNumber(state, { payload }) {
            state.extensionNumber = payload;
        },

        setMonitoringObjectCameras(state, { payload }) {
            state.monitoringObjectCameras = payload;
        },

        getMonitoringObjects(state) {
            state.monitoringObjects = [];
        },

        getMonitoringObjectCameras(state) {
            state.monitoringObjectCameras = [];
        },

        filterCameras(state) {
            const filterCriteria = state.filterCriteria;
            const filtered = state.audioConfiguredCameras.filter((record) => {
                let showRecord = false;

                if (filterCriteria.text) {
                    showRecord =
                        includesText(record.yardName, filterCriteria.text) ||
                        includesText(record.name, filterCriteria.text) ||
                        includesText(record.audioType, filterCriteria.text) ||
                        includesText(record.id, filterCriteria.text) ||
                        includesText(record?.monitoringObjects?.[0]?.label, filterCriteria.text);
                } else showRecord = true;

                filterCriteria.audioType[record.audioType] === false && (showRecord = false);
                return showRecord;
            });
            const isFiltered = filtered.length !== state.filteredCameras.length;
            if (isFiltered) state.filteredCameras = filtered;
        },

        setAudioConfiguredCameras(state, { payload }) {
            state.audioConfiguredCameras = payload;
            state.filteredCameras = payload;
        },

        setDialog(state, { payload: { open, mode } }) {
            state.dialog.open = open;
            state.dialog.mode = mode;
            if (!open) {
                //clear values on dilaog close
                state.dialog.mode = AUDIO_CONFIGURATION_DIALOG_MODE.NEW;
                state.selectedLocation = null;
                state.selectedMonitoringObject = null;
                state.monitoringObjects = [];
                state.selectedCamera = null;
                state.monitoringObjectCameras = [];
                state.extensionNumber = undefined;
                state.selectedAudioType = '';
            }
        },

        setInitialState(state) {
            Object.keys(state).forEach((key) => {
                state[key] = initialState[key];
            });
        },

        setFilterCriteriaText(state, { payload: text }) {
            state.filterCriteria.text = text;
        },
        setFilterCriteriaAudioType(state, { payload: { audioType, value } }) {
            state.filterCriteria.audioType[audioType] = value;
        },

        setErrorMessage(state, { payload }) {
            state.errorMessage = payload;
        },

        //placeholders
        submitChanges() {},
        saveCameraConfiguration() {},
        openExistingRecord() {},
        getAllLocations() {},
        getAudioTypes() {},
        getAudioConfiguredCameras() {},
        getInitialData() {},
    },
});

export const audioConfigurationActions = audioConfigurationSlice.actions;
export const audioConfigurationReducer = audioConfigurationSlice.reducer;
export const audioConfigurationName = audioConfigurationSlice.name;

//utils
const includesText = (field, criteria) => {
    return String(field).toLowerCase().includes(criteria.toLowerCase().replace(/\s+/g, ' ').trim());
};
