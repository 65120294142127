import { PERMISSION_NAME } from 'utils/constants';

export const transformInviteUserFormData = (formData) => {
    if (!formData) return null;
    const { email, username, active = false, orgStates = {} } = formData;
    if (!email?.trim() && !username?.trim()) return null;

    // Transform user data
    const user = {
        username: username.trim(),
        email: email.trim(),
        active: active ?? false,
    };

    // Transform location feature permissions
    const locationFeaturePermissions = [];

    // Iterate through each organization's locations
    Object.values(orgStates).forEach((org) => {
        if (!org.enabled) return;

        Object.entries(org.locations).forEach(([locationId, location]) => {
            if (!location.enabled) return;

            const features = [];

            // Transform each feature's permissions
            Object.entries(location.permissions).forEach(([featureId, featurePermissions]) => {
                // Only add features that have permissions
                if (featurePermissions.permissions?.length > 0) {
                    features.push({
                        id: parseInt(featureId),
                        permissions: featurePermissions.permissions,
                    });
                }
            });
            locationFeaturePermissions.push({
                location: {
                    id: parseInt(locationId),
                },
                displayName: location.displayName?.trim() || '',
                features,
            });
        });
    });

    return {
        user,
        locationFeaturePermissions,
    };
};

export const formatUserPermissionDetail = ({ userPermissionDetail, organizations }) => {
    if (!userPermissionDetail) return null;

    const { user = {}, locationFeaturePermissions = [] } = userPermissionDetail;
    const featuresObj = {};
    const organizationsObj = [];

    locationFeaturePermissions.forEach((entry) => {
        entry.features.forEach((feature) => {
            if (!featuresObj[feature.id]) {
                featuresObj[feature.id] = { id: feature.id, name: feature.name };
            }
        });
    });

    const features = Object.values(featuresObj);

    const orgStates = locationFeaturePermissions.reduce((acc, { location, displayName, features }) => {
        if (!location) return acc;

        const { id: locationId, yardName, organization } = location;
        const { id: orgId, name: orgName } = organization || {};

        if (!orgId) return acc;

        if (!organizationsObj.some((org) => org.id === orgId)) {
            organizationsObj.push({ id: orgId, name: orgName });
        }

        if (!acc[orgId]) {
            acc[orgId] = { orgId, orgName, enabled: true, locations: {} };
        }

        if (!acc[orgId].locations[locationId]) {
            acc[orgId].locations[locationId] = { id: locationId, enabled: true, yardName, displayName, permissions: {} };
        }

        features.forEach(({ id: featureId, name, permissions }) => {
            acc[orgId].locations[locationId].permissions[featureId] = {
                viewOnly: permissions.some((p) => p.name === PERMISSION_NAME.VIEW),
                fullAccess: permissions.some((p) => p.name === PERMISSION_NAME.FULL_ACCESS),
                permissions,
                name,
            };
        });

        return acc;
    }, {});

    // Add missing locations not returned from API
    organizations.forEach(({ id: orgId, locations }) => {
        if (!orgStates[orgId]) return;

        locations.forEach(({ id: locationId, yardName }) => {
            if (!orgStates[orgId].locations[locationId]) {
                orgStates[orgId].locations[locationId] = {
                    id: locationId,
                    enabled: false,
                    yardName,
                    displayName: '',
                    permissions: {},
                };
            }
        });
    });

    return {
        email: user.email,
        username: user.username,
        active: user.active,
        orgStates,
        features,
        organizations: organizationsObj,
    };
};
