import { createSlice } from '@reduxjs/toolkit';
import { set } from 'lodash';

const clientPersonnelSlice = createSlice({
    name: 'clientPersonnel',
    initialState: {
        organizationInfo: [],
        clientPersonnelTableInfo: [],
        paginationInfo: {
            elementsPerPage: 0,
            totalElements: 0,
            totalPages: 0,
        },
        isLoadingPersonneTablelInfo: false,
        isLoadingPersonnelInfo: false,
        isLoadingOrganizationInfo: false,
        isFetchingPersonnelInfo: false,
        personnelInfo: {},
        personnelInfoErrorMessage: '',
        authorizedLocations: [],
        loading: false,
        error: null,
    },
    reducers: {
        fetchClientPersonnelTableInfoRequest: (state) => {
            state.isLoadingPersonneTablelInfo = true;
        },
        fetchClientPersonnelTableInfoSuccess: (state, action) => {
            state.isLoadingPersonneTablelInfo = false;
            state.isFetchingPersonnelInfo = true;
            state.clientPersonnelTableInfo = action.payload;
        },
        fetchClientPersonnelTableInfoError: (state) => {
            state.isLoadingPersonneTablelInfo = false;
            state.isFetchingPersonnelInfo = false;
        },
        fetchOrganizationInfo: (state) => {
            state.isLoadingOrganizationInfo = true;
        },
        fetchOrganizationInfoSuccess: (state, action) => {
            state.isLoadingOrganizationInfo = false;
            state.organizationInfo = action.payload;
        },
        setPaginationInfo: (state, action) => {
            state.paginationInfo = action.payload;
        },
        fetchOrganizationInfoError: (state) => {
            state.isLoadingOrganizationInfo = false;
        },
        fetchAuthorizedLocationsRequest: (state) => {
            state.loading = true;
        },
        fetchAuthorizedLocations: (state, action) => {
            state.loading = false;
            state.authorizedLocations = action.payload;
        },
        fetchAuthorizedLocationsError: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        fetchClientPersonnelByIdRequest: (state) => {
            state.isLoadingPersonnelInfo = true;
        },
        fetchClientPersonnelInfoSuccess: (state, action) => {
            state.isLoadingPersonnelInfo = false;
            state.personnelInfo = action.payload;
        },
        fetchClientPersonnelInfoError: (state, action) => {
            state.personnelInfoErrorMessage = action.payload;
            state.isLoadingPersonnelInfo = false;
        },
        resetState: (state) => {
            state.authorizedLocations = [];
            state.personnelInfo = {};
            state.loading = false;
            state.error = null;
        },
    },
});

export const clientPersonnelActions = clientPersonnelSlice.actions;
export const clientPersonnelReducer = clientPersonnelSlice.reducer;
