import { MONITORING_OBJECT_TYPE } from './constants';

export const monitoringObject = {
    locationListForm: {
        id: 'monitoringObjectForm',
        fields: [
            {
                fieldName: 'active',
                label: 'Active',
                type: 'text',
                initialValue: false,
                class: 'mo-status-switch',
                element: 'switch',
            },
            {
                fieldName: 'name',
                label: 'Name',
                type: 'text',
                initialValue: null,
                required: true,
                element: 'input',
            },
            {
                fieldName: 'objectType',
                label: 'Type',
                type: 'text',
                initialValue: null,
                required: true,
                disabled: false,
                lookup: { displayExpr: 'objectType', valueExpr: 'id', dataSource: MONITORING_OBJECT_TYPE },
                element: 'select',
            },
            {
                fieldName: 'abbreviation',
                label: 'Abbreviation',
                type: 'text',
                initialValue: null,
                required: false,
                element: 'input',
            },

            {
                fieldName: 'description',
                label: 'Description',
                type: 'text',
                initialValue: null,
                required: false,
                element: 'input',
            },
        ],
    },
};
