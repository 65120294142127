import { useDataFetcher } from 'hooks/useDataFetcher';
import { useMutation } from 'hooks/useMutation';
import { showNotification } from 'utils/notificationsHandler';
import { useCallback } from 'react';
import {
    fetchFeatures,
    fetchPermissionPresets,
    inviteUserPermission,
    fetchUserPermissionDetail,
    updateUserPermission,
    fetchFeaturesByCurrentUserRole,
} from 'services/userPermissionService';
import { formatUserPermissionDetail } from '../features/userPermissions/helper/transformInviteUserFormData';

const useUserPermissionPage = () => {
    const { data: permissionPresets = [], isFetching: loadingPresets, fetchData: fetchListPresets } = useDataFetcher(fetchPermissionPresets);
    const { data: featuresAndOrgs = {}, isFetching: loadingFeatures, fetchData: fetchListFeatures } = useDataFetcher(fetchFeatures);
    const {
        data: featuresByCurrentUserRole = [],
        isFetching: loadingFeaturesByCurrentUserRole,
        fetchData: fetchListFeaturesByCurrentUserRole,
    } = useDataFetcher(fetchFeaturesByCurrentUserRole);
    const {
        data: userPermissionDetail = {},
        isFetching: loadingUserPermission,
        fetchData: fetchUserDetail,
    } = useDataFetcher(fetchUserPermissionDetail);

    const onInviterSuccess = useCallback(() => {
        showNotification({
            title: ' User was invited successfully.',
            variant: 'success',
        });
    }, []);

    const onUpdateSuccess = useCallback(() => {
        showNotification({
            title: 'User was updated successfully.',
            variant: 'success',
        });
    }, []);

    const submitInviteUser = async (data) => {
        const response = await inviteUserPermission(data);
        return response;
    };

    const submitUpdateUser = async ({ id, data }) => {
        const response = await updateUserPermission(id, data);
        return response;
    };

    const { mutate: onSubmitInviteUser, isLoading: inviteUserLoading } = useMutation({
        mutationFn: submitInviteUser,
        onSuccess: onInviterSuccess,
    });

    const { mutate: onUpdateUserPermission, isLoading: updateUserLoading } = useMutation({
        mutationFn: submitUpdateUser,
        onSuccess: onUpdateSuccess,
    });

    const loading = loadingPresets || loadingFeatures || inviteUserLoading || updateUserLoading || loadingUserPermission;

    const { features = [], organizations = [] } = featuresAndOrgs;

    const formattedUserPermission = userPermissionDetail ? formatUserPermissionDetail({ userPermissionDetail, organizations }) : {};

    return {
        loading,
        permissionPresets,
        features,
        organizations,
        userPermissionDetail: formattedUserPermission,
        onSubmitInviteUser,
        onUpdateUserPermission,
        fetchListPresets,
        fetchListFeatures,
        fetchUserDetail,
        featuresByCurrentUserRole,
        loadingFeaturesByCurrentUserRole,
        fetchListFeaturesByCurrentUserRole,
    };
};

export default useUserPermissionPage;
