import useGlobalLocation from 'components/globalLocationSelector/hooks/useGlobalLocation';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { useSelector } from 'react-redux';
import { selectUser } from 'store/auth/authSelectors';
import { FEATURE_ROLE_PERMISSION_MATRIX_MAPPING } from 'utils/featureAndRolePermissions';
import {
    hasCreatePermissionOnFeature,
    hasDeletePermissionOnFeature,
    hasEditPermissionOnFeature,
    hasReadPermissionOnFeature,
} from 'utils/userPermissionHelper';

const useUserPermission = (featureName) => {
    const user = useSelector(selectUser);
    const { selectedLocationId } = useGlobalLocation();
    const { isUserPermissionEnabled } = useFeatureFlag();

    const roleMatrix = FEATURE_ROLE_PERMISSION_MATRIX_MAPPING[featureName] || {};

    const isViewable = user?.roles?.some((v) => roleMatrix.view?.includes(v));
    const isCreatable = user?.roles?.some((v) => roleMatrix.create?.includes(v));
    const isEditable = user?.roles?.some((v) => roleMatrix.edit?.includes(v));
    const isRemovable = user?.roles?.some((v) => roleMatrix.remove?.includes(v));

    if (!isUserPermissionEnabled)
        return {
            create: isCreatable,
            edit: isEditable,
            read: isViewable,
            delete: isRemovable,
        };

    const permissions = {
        create: isCreatable || hasCreatePermissionOnFeature(user, selectedLocationId, featureName),
        edit: isEditable || hasEditPermissionOnFeature(user, selectedLocationId, featureName),
        read: isViewable || hasReadPermissionOnFeature(user, selectedLocationId, featureName),
        delete: isRemovable || hasDeletePermissionOnFeature(user, selectedLocationId, featureName),
    };

    return permissions;
};

export default useUserPermission;
