export const MONITORING_OBJECT_TYPE = [
    { objectType: 'DOOR', id: 'DOOR' },
    { objectType: 'YARD', id: 'YARD' },
    { objectType: 'FENCE', id: 'FENCE' },
    { objectType: 'PARKING', id: 'PARKING' },
    { objectType: 'GATE IN', id: 'GATE_IN' },
    { objectType: 'GATE OUT', id: 'GATE_OUT' },
];

export const FORM_STATUS = {
    ADD: 'add',
    EDIT: 'edit',
};
