import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { Box, Button, Divider, Link, List, ListItemText, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';
import { useState } from 'react';

const HelpDialogDetail = () => {
    const instructions = [
        {
            key: 'editName',
            title: 'Edit Yard Check Name',
        },
        {
            key: 'cancelYardCheck',
            title: 'Cancel Yard Check',
        },
        {
            key: 'reviewStage',
            title: 'Turn Yard Check To Review Stage',
        },
        {
            key: 'searchAndAdd',
            title: 'Asset Search and Add to Checking List',
        },
        {
            key: 'manuallyAdd',
            title: 'Manually Add Items to Checking List',
        },
        {
            key: 'removeItems',
            title: 'Remove Items from Checking List',
        },
        {
            key: 'updateInfo',
            title: 'Update Asset Information',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <Typography>Items in the Yard Check can fall into one of the following statuses:</Typography>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Matched:</b> Vehicles or equipment exist in both the physical Location and the digital Inventory.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>New:</b> Vehicles or equipment are present in the physical Location but are not recorded in the digital Inventory.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Mismatched:</b> Vehicles or equipment are recorded in the digital Inventory but are not present in the physical Location.
                    </span>
                </ListItemText>
            </List>
            <Typography>
                For <b>Open</b> and <b>Checking</b> statuses, the Yard Check feature allows users to:
            </Typography>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    Add new items to the Yard Check: Lasta will look up matching assets in the Current Inventory while also allowing users to add new
                    items to the Yard Check.
                </ListItemText>
                <ListItemText className={styles['list-item']}>Remove items from the Yard Check: Remove an item from Checking List.</ListItemText>
            </List>
            <Typography>
                All items will remain in the Yard Check until users complete the process. Once the Yard Check is finished, all changes will be pushed
                to the <b>Current Inventory</b> with the selected remedial actions applied.
            </Typography>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Yard Check Information</TableCell>
                        <TableCell>Display basic information of Yard Check.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>[Cancel] Button</TableCell>
                        <TableCell>Close Yard Check details and return to Yard Check List.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>[Review] Button</TableCell>
                        <TableCell>Wrap up items in Yard Check and proceed to Review stage.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Yard Check Name Edit</span>
                            <span>
                                <BorderColorIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Allows users to edit Yard Check Name.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Yard Check Summary</TableCell>
                        <TableCell>
                            Summary numbers of Yard Check, including:
                            <List className={styles['unordered-list']}>
                                <ListItemText className={styles['list-item']}>
                                    <b>Inventory:</b> Total number of Assets in <b>Current Inventory</b>.
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Yard Check:</b> Total number of Items in Yard Check.
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Difference:</b> Total number of unchecked Assets in <b>Current Inventory</b>.
                                </ListItemText>
                            </List>
                            By expanding this summary section, users can see detail summary of each Asset Type compare with <b>Current Inventory</b>.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Asset Search Combo Box</TableCell>
                        <TableCell>
                            Allows users to input keywords of checking Asset. Lasta shall lookup for matching Assets in <b>Current Inventory</b> if
                            existed. Users can then select an option in suggested list to add Asset to Checking List.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>[Add New Item] Button</TableCell>
                        <TableCell>
                            If the entered keywords doesn&apos;t have any matching Asset in <b>Current Inventory</b>. This button will display
                            allowing users to add new item into Yard Check without presence in Current Inventory.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Item Status</TableCell>
                        <TableCell>Status of item in Yard Check.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Action Options</span>
                            <span>
                                <MoreVertIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Open available options to interact with a specific Item in Yard Check.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="24rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                                    ${selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{renderInstructionContent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderInstructionContent = (key) => {
        switch (key) {
            case 'editName':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Edit Yard Check Name</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Click on the edit icon (<BorderColorIcon size="small" className={styles['inlined-icon']} />) beside the Yard Check
                                Name to enable the inline editor.
                            </ListItemText>
                            <ListItemText>2. Enter the new Yard Check Name.</ListItemText>
                            <ListItemText>3. Press Enter to save the change.</ListItemText>
                        </List>
                        <p className={styles['instruction-header']}>
                            {' '}
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'cancelYardCheck':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Cancel Yard Check</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Click on the <b>[Cancel]</b> button to close the Yard Check detail page and return to the Yard Check List.
                            </ListItemText>
                            <ListItemText>2. All changes made in the Yard Check will be retained for the next opening.</ListItemText>
                        </List>
                    </Box>
                );
            case 'reviewStage':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Review Yard Check</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Click on the <b>[Review]</b> button to wrap up items made in the Yard Check and proceed to review and remedial
                                actions.
                            </ListItemText>
                        </List>
                        <p className={styles['instruction-header']}>
                            {' '}
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'searchAndAdd':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Asset Search and Add to Checking List</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. You can type keywords in Asset Search Combo Box. The value can be anything related to checking Asset.
                            </ListItemText>
                            <ListItemText>
                                2. Lasta will lookup to get matching Asset List if possible and display all of them as a list of suggestions.
                            </ListItemText>
                            <ListItemText>
                                3. You can choose one item in suggestions list and that item will be brought from Remaining List to Checking List,
                                which means that item is <b>Matched</b>.
                            </ListItemText>
                        </List>
                        <p>
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'manuallyAdd':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Manually Add Items to Checking List</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. You can type keywords in Asset Search Combo Box. The value can be anything related to checking Asset.
                            </ListItemText>
                            <ListItemText>
                                2. Lasta will lookup to get matching Asset List if possible and display all of them as a list of suggestions.
                            </ListItemText>
                            <ListItemText>
                                3. If no matching Asset can be found with given keywords. Lasta will display button [Add New Item]. Clicking on this
                                button will display a modal popup with form allowing you to enter information for new item. This item will then be
                                added into Checking List as <b>New</b>.
                            </ListItemText>
                        </List>
                        <p>
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'removeItems':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Remove Items from Checking List</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Click on <MoreVertIcon size="small" className={styles['inlined-icon']} /> menu of an Yard Check then choose
                                “Remove” to open remove confirmation prompt.
                            </ListItemText>
                            <ListItemText>
                                2. Click [Yes] button to confirm removing Item from Checking List, this action cannot be undone.
                            </ListItemText>
                            <ListItemText>3. Click [Cancel] button to close the confirmation prompt and back to Yard Check detail.</ListItemText>
                        </List>
                        <p>
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            case 'updateInfo':
                return (
                    <Box className={styles['instruction-child-container']}>
                        <p className={styles['instruction-header']}>Update Asset Information</p>
                        <List className={styles['unordered-list']}>
                            <ListItemText>
                                1. Double-click on an item in the Checking List or click on the{' '}
                                <MoreVertIcon size="small" className={styles['inlined-icon']} /> menu and choose <b>Edit</b>
                            </ListItemText>
                            <ListItemText>2. A modal popup will appear, allowing you to update the item’s information.</ListItemText>
                        </List>
                        <p className={styles['instruction-header']}>
                            {' '}
                            <i>* Only privileged users can edit Yard Check details.</i>
                        </p>
                    </Box>
                );
            default:
                return null;
        }
    };

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderInstructionsSection()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialogDetail;
