import { useSelector } from 'react-redux';
import { selectFeatureFlagData } from 'store/featureFlag/featureFlagSelector';

const useFeatureFlag = () => {
    const {
        clientRequestEnabled: isClientRequestEnabled,
        ssoenabled: isSsoEnabled,
        inventoryEnabled: isInventoryEnabled,
        yardCheckEnabled: isYardCheckEnabled,
        dynamicFormsEnabled: isDynamicFormsEnabled,
        slackEnabled: isSlackEnabled,
        userPermissionEnabled: isUserPermissionEnabled,
    } = useSelector(selectFeatureFlagData) ?? {};

    return {
        isSsoEnabled,
        isClientRequestEnabled,
        isInventoryEnabled,
        isYardCheckEnabled,
        isDynamicFormsEnabled,
        isSlackEnabled,
        isUserPermissionEnabled,
    };
};

export default useFeatureFlag;
