import { FEATURE_LEVEL, FEATURE_NAME, ROLES } from 'utils/constants';

export const APPOINTMENT_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const INVENTORY_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const AUTHORIZE_PEOPLE_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};
export const AUTHORIZE_ORGANIZATION_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.AGENT, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const YARD_CHECK_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const APR_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const USER_AND_PERMISSION_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN],
    create: [ROLES.ADMIN],
    edit: [ROLES.ADMIN],
    remove: [ROLES.ADMIN],
};

export const CLIENT_REQUEST_ROLE_PERMISSION_MATRIX = {
    view: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    create: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    edit: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
    remove: [ROLES.ADMIN, ROLES.FACILITY_MANAGER, ROLES.ORGANIZATION_ADMIN, ROLES.TEAM_LEADER],
};

export const FEATURE_ROLE_PERMISSION_MATRIX_MAPPING = {
    [FEATURE_NAME.APPOINTMENTS]: APPOINTMENT_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.INVENTORY]: INVENTORY_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.ACCESS_POINT_RECORDS]: APR_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.YARD_CHECK]: YARD_CHECK_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.AUTHORIZED_LIST_PEOPLE]: AUTHORIZE_PEOPLE_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.AUTHORIZED_LIST_ORGANIZATION]: AUTHORIZE_ORGANIZATION_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.USER_AND_PERMISSION]: USER_AND_PERMISSION_ROLE_PERMISSION_MATRIX,
    [FEATURE_NAME.CLIENT_REQUEST]: CLIENT_REQUEST_ROLE_PERMISSION_MATRIX,
};

export const FEATURE_LEVEL_USER_ROLE_MAPPING = {
    [ROLES.EXTERNAL_USER]: [FEATURE_LEVEL.CLIENT],
    [ROLES.USER]: [FEATURE_LEVEL.CLIENT],
    [ROLES.ADMIN]: [FEATURE_LEVEL.ROOT, FEATURE_LEVEL.CLIENT],
    [ROLES.AGENT]: [FEATURE_LEVEL.ROOT, FEATURE_LEVEL.CLIENT],
    [ROLES.FACILITY_MANAGER]: [FEATURE_LEVEL.ROOT, FEATURE_LEVEL.CLIENT],
    [ROLES.ORGANIZATION_ADMIN]: [FEATURE_LEVEL.ROOT, FEATURE_LEVEL.CLIENT],
    [ROLES.TEAM_LEADER]: [FEATURE_LEVEL.ROOT, FEATURE_LEVEL.CLIENT],
};
