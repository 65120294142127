import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import React, { useState } from 'react';

import { TextField, Typography } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';
import { AuthTypeSwitch } from 'features/authorizedList/sidePanel/SidePanelStyled';

const HelpDialog = () => {
    const instructions = [
        {
            key: 'search',
            title: 'Yard Check Search',
        },
        {
            key: 'filter',
            title: 'Yard Check Statuses Filter',
        },
        {
            key: 'addNew',
            title: 'Adding new Yard Check',
        },
        {
            key: 'open',
            title: 'Open Yard Check',
        },
        {
            key: 'remove',
            title: 'Remove Yard Check',
        },
    ];

    const [selectedInstruction, setSelectedInstruction] = useState(instructions[0].key);
    const getInstructionComponent = (key) => {
        switch (key) {
            case 'filter':
                return renderFilterInstruction();
            case 'addNew':
                return renderAddNewInstruction();
            case 'open':
                return renderOpenInstruction();
            case 'remove':
                return renderRemoveInstruction();
            default:
                return renderSearchInstruction();
        }
    };

    const renderFilterInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Yard Check Statuses Filter</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>By default, all statuses are selected, and all Yard Checks are displayed in the list.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>You can turn on/off any status filter.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>The Yard Check List will display Yard Checks according to the turned-on filters.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>A message will appear if no Yard Check matches the selected filters.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderAddNewInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Adding New Yard Check</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Click on the
                        <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                        button to start adding a new Yard Check.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>A prompt message with basic information for new Yard Check which includes:</span>
                    <ul>
                        <li>
                            <b>Name:</b> Name of new Yard Check, this name is auto populated base on timestamp creating this new Yard Check.
                        </li>
                        <li>
                            <b>Start Date:</b> Indicate date and time that this new Yard Check was created.
                        </li>
                        <li>
                            <b>[Cancel] button</b> Close the prompt message and don’t create any Yard Check.
                        </li>
                        <li>
                            <b>[Create] button</b> Confirm basic information and add new Yard Check.
                        </li>
                    </ul>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Click on [Create] button will create new empty Yard Check, Lasta will navigate to Yard Check detail page so you can start your
                        Yard Check session.
                    </span>
                </ListItemText>
            </List>
            <p>
                <i>* Only privileged users can add new Yard Check.</i>
            </p>
        </Box>
    );

    const renderOpenInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Open Yard Check</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Double click on a Yard Check or click on
                        <MoreVertIcon size="small" className={styles['inlined-icon']} />
                        menu then choose “View” to navigate to details of Yard Check.
                    </span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderRemoveInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Remove Yard Check</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Click on
                        <MoreVertIcon size="small" className={styles['inlined-icon']} />
                        menu of a Yard Check then choose “Remove” to open remove confirmation prompt.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click [Yes] button to confirm removing Yard Check, this action cannot be undone.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Click [Cancel] button to close the confirmation prompt and back to Yard Check List.</span>
                </ListItemText>
            </List>
            <p>
                <i>* Only privileged users can remove Yard Check.</i>
            </p>
        </Box>
    );

    const renderSearchInstruction = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Yard Check Search</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>Type keywords into the Search box.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>Press Enter to start searching.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>The Yard Check List will narrow down to display only Yard Check that match the entered keywords.</span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>A message will appear if no matching Yard Check are found in the viewing Yard Check List.</span>
                </ListItemText>
            </List>
        </Box>
    );

    const renderDivider = () => <Divider className={styles['divider']} />;

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            <p className={styles['description']}>
                The Yard Check feature allows Lasta users to perform a checklist comparison with the <b>Current Inventory</b> of a selected Location.
                This enables users to:
            </p>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Compare the physical presence of vehicles or equipment at a Location with the digital records stored in{' '}
                        <b>Current Inventory</b> of Lasta.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Determine and take corrective actions for any newly found, mismatched, or updated items in the <b>Current Inventory</b> of
                        Lasta.
                    </span>
                </ListItemText>
            </List>
            <p className={styles['description']}>A Yard Check can have one of the following statuses:</p>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Open:</b> The Yard Check has been newly created, and no actions have been taken on its checklist.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Checking:</b> The Yard Check has been created, and users have performed some actions on its checklist.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        <b>Review then Finished:</b> Users have completed the actions and are now reviewing all changes to decide on the necessary
                        remedial actions for each item in the Yard Check then have finalized the remedial actions and pushed all changes to the
                        current inventory at the committed timestamp.
                    </span>
                </ListItemText>
            </List>
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Add New Yard Check</span>
                            <span>
                                <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Add a new Yard Check for the selected location.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Search box</span>
                            <Stack paddingTop={2}>
                                <TextField disabled placeholder="Search..." variant="outlined" size="small" />
                            </Stack>
                        </TableCell>
                        <TableCell>
                            Enter keywords to search for specific Yard Check in Yard Check List.
                            <br /> The search is performed globally across multiple columns.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Status Filter</span>
                            <Stack paddingTop={2} display="flex" flexDirection="row">
                                <Typography>Open</Typography>
                                <AuthTypeSwitch checked />
                            </Stack>
                        </TableCell>
                        <TableCell>
                            <span>Switch between Inventory Views</span>
                            <ListItemText className={styles['list-item']}>
                                <b>Open:</b> view Yard Checks in Open status.
                            </ListItemText>
                            <ListItemText className={styles['list-item']}>
                                <b>Checking:</b> view Yard Checks in Checking status.
                            </ListItemText>
                            <ListItemText className={styles['list-item']}>
                                <b>Finished:</b> view Yard Checks in Finished status.
                            </ListItemText>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Action Options</span>
                            <span>
                                <MoreVertIcon size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Open available options to interact with a specific Yard Check in Yard Check List.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INSTRUCTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="24rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {instructions.map((instruction) => (
                            <Button
                                key={instruction.key}
                                variant="outlined"
                                size="small"
                                onClick={() => {
                                    setSelectedInstruction(instruction.key);
                                }}
                                className={`${styles['instruction-button']}
                                    ${selectedInstruction === instruction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                            >
                                {instruction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInstructionComponent(selectedInstruction)}</Box>
            </Box>
        </>
    );

    const renderFooter = () => (
        <Stack className={styles['footer']}>
            <span>
                {`For further support, email us at `}
                <Link href="mailto:cs@birdseye.ca" underline="none">
                    cs@birdseye.ca
                </Link>
                {'.'}
            </span>
        </Stack>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                {renderDivider()}
                {renderControlsSection()}
                {renderDivider()}
                {renderInstructionsSection()}
            </Box>
            {renderFooter()}
        </Stack>
    );
};

export default HelpDialog;
