import { isDate, isEmpty, isNil, isObject, reduce } from 'lodash';
import * as fieldControlers from 'store/formControl/fieldControllers';
import { FIELD_FILTER_OPERATIONS } from './constants';

export const getFormFieldConfig = function ({ fieldConfig, formId, helperText = 'This field is required', disabled = false }) {
    const { required, label } = fieldConfig;

    return {
        ...fieldConfig,
        ...fieldControlers,
        label: required ? `${label} *` : label,
        formId,
        helperText,
        disabled,
    };
};

export const getLookup = (lookup, dropdownValues, fieldName) => {
    return {
        ...lookup,
        dataSource:
            !lookup?.dataSource && dropdownValues[fieldName]?.dataSource
                ? dropdownValues[fieldName]?.dataSource
                : lookup?.dataSource
                  ? lookup?.dataSource
                  : [],
    };
};

/**
 * Groups fields by their header and returns an array of objects.
 *
 * Each object in the resulting array contains a `caption`, which is the
 * header of the grouped fields, and a `columns` array that holds the
 * corresponding fields associated with that header.
 *
 * @param {Array} fields - An array of field objects where each object
 *                         must contain a `header` property.
 * @returns {Array} An array of objects, each containing:
 *                  - `caption`: The header of the group.
 *                  - `columns`: An array of field objects associated
 *                                with that header.
 *
 *
 * @example
 *  Input:
 *  const fields = [
 *     { '@type': 'Field', caption: 'Started', dataField: 'startedDateTime', header: 'General' },
 *     { '@type': 'Field', caption: 'Direction', dataField: 'gateDirection', header: 'General' },
 *     { '@type': 'Field', caption: 'Full name', dataField: 'person.fullName', header: 'Person' },
 *  ];
 *
 *  Output:
 *  [
 *      { caption: 'General', columns: [ * General fields * ] },
 *      { caption: 'Person', columns: [ * Person fields * ] }
 *  ]
 */
export const groupFieldsByHeader = (fields = []) => {
    return fields.reduce((acc, field) => {
        const { header } = field;
        // Check if the header already exists in the accumulator
        let group = acc.find((g) => g.caption === header);

        if (!group) {
            // If it doesn't exist, create a new group
            group = { caption: header, columns: [], type: 'object' };
            acc.push(group);
        }

        // if the field is type list then add type to the group
        if (field.dataType === 'list') {
            group.type = field.dataType;
            group.dataField = field.dataField;
        } else {
            // set default filter operations for the field
            field.filterOperations = FIELD_FILTER_OPERATIONS;
            // Push the field into the corresponding group's columns
            if (field.dataField?.includes('[]')) {
                group.columns.push({ ...field, dataType: 'array', dataField: field.dataField.replace('[]', '.array') });
            } else group.columns.push(field);
        }
        return acc;
    }, []);
};

/**
 * Applies multiple transformation functions sequentially to the "columns" array of each section.
 *
 * @param {Array<Object>} fieldList - The array of objects containing a "columns" array.
 * @param {...Function} transformFns - Transformation functions to apply to the columns.
 * @returns {Array<Object>} A new array with transformed columns.
 */
export const applyTransformations = (fieldList, ...transformFns) => {
    return transformFns.reduce((updatedFields, transformFn) => {
        return updatedFields.map((section) => ({
            ...section,
            columns: transformFn(section.columns),
        }));
    }, fieldList);
};

/**
 * Excludes specific fields from the "columns" array.
 *
 * @param {Array<string>} excludeFieldNameList - List of field names to exclude.
 * @returns {Function} A transformation function that removes specified fields.
 */
export const excludeFields = (excludeFieldNameList) => {
    return (columns) => columns.filter((col) => !excludeFieldNameList.includes(col.dataField));
};

/**
 * Reorders the "gate.id" field to appear before "gateDirection" within the "columns" array.
 *
 * @returns {Function} A transformation function that reorders gate fields.
 */
export const reorderGateBeforeDirection = () => {
    return (columns) => {
        if (!Array.isArray(columns)) {
            return columns;
        }

        const gateIndex = columns.findIndex((col) => col.dataField === 'gate.id');
        const directionIndex = columns.findIndex((col) => col.dataField === 'gateDirection');

        if (gateIndex > -1 && directionIndex > -1 && gateIndex > directionIndex) {
            const [gateField] = columns.splice(gateIndex, 1);
            columns.splice(directionIndex, 0, gateField);
        }

        return columns;
    };
};

export const deepOmitBy = (obj, predicate = isNil) => {
    if (!isObject(obj)) {
        return obj; // Return non-object values as is.
    }

    // Recursively process each property.
    const result = reduce(
        obj,
        (acc, value, key) => {
            if (predicate(value, key) && !isDate(value)) {
                // If the predicate matches, omit this property.
                return acc;
            }

            // Recurse for nested objects or arrays.
            const processedValue = isObject(value) && !isDate(value) ? deepOmitBy(value, predicate) : value;

            // Only add non-empty processed values
            if (processedValue !== undefined && !(isObject(processedValue) && isEmpty(processedValue) && !isDate(value))) {
                acc[key] = processedValue;
            }

            return acc;
        },
        Array.isArray(obj) ? [] : {}
    );

    return result;
};
