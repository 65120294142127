import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLocations: [],
    selectedLocation: null,

    submittingFormInProgress: false,
    submittingFormError: null,
    submittingFormSuccess: false,

    authorizedItems: [],
    isFetchingAuthorizedCompany: false,
    selectedAuthorizedItem: null,

    authorizedItemListAlerts: [],
    selectedAuthorizedItemAlert: null,

    masterList: [],
    selectedMasterList: null,

    formStatus: 'viewOnly',
    authTypeFormStatus: 'viewOnly',
    beforeEditData: null,
    beforeEditDataAuthType: null,

    removePopupVisible: false,
    addPopupVisible: false,
    authTypePopupVisible: false,
    removeAuthTypePopupVisible: false,
    addAuthTypePopupVisible: false,

    personList: [],
    authTypeList: [],
    companyState: [],

    addedCompany: false,
    addingCompanyInProgress: false,
    popupVisible: false,

    searchText: null,
    filterAuthTypes: [],
};

const companyAuthorizationListSlice = createSlice({
    name: 'companyAuthorizedList',
    initialState,
    reducers: {
        fetchAllLocations() {},
        setAllLocations(state, { payload: allLocations }) {
            state.allLocations = allLocations;
        },
        fetchAuthorizedListPerLocation(state) {
            state.submittingFormError = null;
            state.isFetchingAuthorizedCompany = true;
        },
        fetchMasterListStart() {},
        fetchMasterListSuccess(state, { payload: companyList }) {
            state.masterList = companyList;
        },
        setSelectedMasterListItem(state, { payload }) {
            state.selectedMasterList = payload ? state.masterList.find((comapny) => comapny.id === payload) : null;
        },
        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
        },
        fetchAuthorizedLocationSuccess(state, { payload: authorizedItems }) {
            state.authorizedItems = authorizedItems;
            state.isFetchingAuthorizedCompany = false;
        },
        submitFormStart(state) {
            state.submittingFormError = null;
            state.submittingFormSuccess = false;
        },
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
            state.isFetchingAuthorizedCompany = false;
        },
        fetchAuthorizedItemAuthTypesStart() {},
        fetchAuthorizedItemAuthTypes(state, { payload: authTypeList }) {
            state.authorizedItemListAlerts = authTypeList;
        },
        fetchStateStart() {},
        fetchStateSuccess(state, { payload: companyState }) {
            state.companyState = companyState;
        },
        resetItemsAuthorizations(state) {
            state.authorizedItemListAlerts = [];
        },
        setSelectedAuthorizedItem(state, { payload }) {
            state.selectedAuthorizedItem = payload ? state.authorizedItems.find((person) => person.id === payload) : null;
        },
        setSelectedAuthorizedItemDetail(state, { payload }) {
            state.selectedAuthorizedItem = payload;
        },
        setSelectedAlert(state, { payload }) {
            state.selectedAuthorizedItemAlert = payload ? state.authorizedItemListAlerts.find((alert) => alert.id === payload) : null;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        setAuthTypeFormstatus(state, { payload: authTypeFormstatus }) {
            state.authTypeFormstatus = authTypeFormstatus;
        },
        setBeforeEditData(state, { payload: personData = null }) {
            state.beforeEditData = personData;
        },
        setBeforeEditDataAuthType(state, { payload: personAlert = null }) {
            state.beforeEditDataAuthType = personAlert;
        },
        resetAuthorizedState(state) {
            state.selectedLocation = null;
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.formStatus = 'viewOnly';
            state.authorizedItems = [];
            state.selectedAuthorizedItem = null;
            state.authorizedItemListAlerts = [];
            state.selectedAuthorizedItemAlert = null;
            state.beforeEditData = null;
            state.personList = [];
            state.masterList = [];
            state.selectedMasterList = null;
            state.companyState = [];
            state.authTypeList = [];
        },
        setRemovePopupVisible(state, { payload }) {
            state.removePopupVisible = payload;
        },
        setAuthTypePopupVisible(state, { payload: visible }) {
            state.authTypePopupVisible = visible;
        },
        setRemoveAuthTypePopupVisible(state, { payload }) {
            state.removeAuthTypePopupVisible = payload;
        },
        setAddAuthTypePopupVisible(state, { payload }) {
            state.addAuthTypePopupVisible = payload;
        },
        setAddPopupVisible(state, { payload }) {
            state.addPopupVisible = payload;
        },
        addItemToAuthorizedList() {},
        addItemToMasterList() {},
        fetchPersonStart() {},
        fetchPersonError(state, { payload: error }) {
            state.fetchingPersonError = error;
        },
        fetchPersonSuccess(state, { payload: personList }) {
            state.personList = personList;
        },
        setAuthTypeList(state, { payload: authTypes }) {
            state.authTypeList = authTypes;
        },
        fetchAuthTypeList() {},
        deleteAuthorizedItem(state) {
            state.submittingFormSuccess = false;
        },
        deleteSuccess(state) {
            state.submittingFormSuccess = true;
            state.selectedAuthorizedItem = null;
        },
        deleteAuthType() {},
        setAddedCompany(state, { payload: boolean }) {
            state.addedCompany = boolean;
        },
        setAddingCompanyInProgress(state, { payload: boolean }) {
            state.addingCompanyInProgress = boolean;
        },
        setPopupVisible(state, { payload: visible }) {
            state.popupVisible = visible;
        },
        setSearchText(state, { payload: text }) {
            state.searchText = text;
        },
        setFilterAuthTypes(state, { payload: filterAuthTypes }) {
            state.filterAuthTypes = filterAuthTypes;
        },
    },
});

export const companyAuthorizationListActions = companyAuthorizationListSlice.actions;
export const companyAuthorizationListReducer = companyAuthorizationListSlice.reducer;
