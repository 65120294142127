import { AddCircleOutlined, MoreVertOutlined, RefreshOutlined } from '@mui/icons-material';
import { List, ListItemText, MenuItem, Select, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DownloadPdf from 'components/icons/DownloadPdf';
import styles from 'features/DefaultHelp.module.css';

const ControlsSection = () => {
    return (
        <div>
            <Typography variant="h4">CONTROLS</Typography>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Add New Asset</span>
                            <span>
                                <AddCircleOutlined className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>
                            Add a new asset to the <b>Current Inventory</b> of the selected location.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Refresh List</span>
                            <span>
                                <RefreshOutlined className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Refresh the Inventory List to get the latest updates.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Search box</span>
                            <Stack paddingTop={2}>
                                <TextField disabled placeholder="Search..." variant="outlined" size="small" />
                            </Stack>
                        </TableCell>
                        <TableCell>
                            Enter keywords to search for specific assets in the Inventory List.
                            <br /> The search is performed globally across multiple columns.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>List View Dropdown</span>
                            <Stack paddingTop={2}>
                                <Select size="small" disabled defaultValue={'Today'}>
                                    <MenuItem value={'Today'}>
                                        <b>Today</b>
                                    </MenuItem>
                                </Select>
                            </Stack>
                        </TableCell>
                        <TableCell>
                            Switch between Inventory Views
                            <List className={styles['unordered-list']}>
                                <ListItemText className={styles['list-item']}>
                                    <b>Today:</b> view Assets in the <b>Current Inventory</b>.
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Custom:</b> view Assets in the <b>Point-In-Time Inventory</b>.
                                </ListItemText>
                            </List>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Action Options</span>
                            <span>
                                <MoreVertOutlined size="small" className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Open available options to interact with a specific asset in the inventory.</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Download Options</span>
                            <span>
                                <DownloadPdf className={styles['inlined-icon']} />
                            </span>
                        </TableCell>
                        <TableCell>Export the viewing Inventory List in the preferred file format.</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </div>
    );
};

export default ControlsSection;
