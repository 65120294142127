import { createSlice } from '@reduxjs/toolkit';
import { isEqual } from 'lodash';

const initialState = {
    locationValues: [],
    selectedMonitoringObject: null,
    dialogOpen: false,
    locations: [],
    isEditing: false,
    formStatus: 'viewOnly',
    submittingFormError: null,
};

const monitoringObjectList = createSlice({
    name: 'monitoringObjectList',
    initialState,
    reducers: {
        fetchTableValues() {},
        setTableValues(state, { payload: locationValues }) {
            state.locationValues = locationValues;
        },
        setSelectedMonitoringObject(state, { payload: newMonitoringObject }) {
            const selectedMonitoringObject = state.selectedMonitoringObject;
            const sameObject = isEqual(selectedMonitoringObject, newMonitoringObject);
            if (!sameObject) state.selectedMonitoringObject = newMonitoringObject;
        },
        setDialogOpen(state, { payload: dialogOpen }) {
            state.dialogOpen = dialogOpen;
        },

        resetState(state) {
            state = initialState;
        },

        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
        },
        fetchLocation() {},
        setFetchedType(state, { payload }) {
            state.locations = payload;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        submitFormStart() {},
        submitFormFailed(state, { payload: error }) {
            state.submittingFormError = error;
        },
        setIsEditing(state, { payload }) {
            state.isEditing = payload;
        },
        fetchSingleMonitoringObject() {},
    },
});

export const monitoringObjectActions = monitoringObjectList.actions;
export const monitoringObjectReducer = monitoringObjectList.reducer;
