import { createSelector } from '@reduxjs/toolkit';

export const companyAuthorizationListSlice = (state) => state.companyAuthorizationList;

export const selectAllLocationsLookup = createSelector([companyAuthorizationListSlice], (slice) => {
    const dataSource = slice.allLocations;
    return { displayExpr: 'yardName', dataSource };
});

export const selectSelectedLocation = createSelector([companyAuthorizationListSlice], (slice) => slice.selectedLocation);

export const selectAuthoriziedItemList = createSelector([companyAuthorizationListSlice], (slice) => slice.authorizedItems);
export const selectIsFetchingAuthorizedCompany = createSelector([companyAuthorizationListSlice], (slice) => slice.isFetchingAuthorizedCompany);
export const selectAuthoriziedItem = createSelector([companyAuthorizationListSlice], (slice) => slice.selectedAuthorizedItem);

export const selectAuthoriziedfetching = createSelector([companyAuthorizationListSlice], (slice) => slice.submittingFormInProgress);

export const selectAuthoriziedErrors = createSelector([companyAuthorizationListSlice], (slice) => slice.submittingFormError);

export const selectAuthoriziedItemAlerts = createSelector([companyAuthorizationListSlice], (slice) => slice.authorizedItemListAlerts);
export const selectAuthoriziedItemAlert = createSelector([companyAuthorizationListSlice], (slice) => slice.selectedAuthorizedItemAlert);

export const selectAuthoriziedItemFormStatus = createSelector([companyAuthorizationListSlice], (slice) => slice.formStatus);
export const selectAuthTypeFormstatus = createSelector([companyAuthorizationListSlice], (slice) => slice.authTypeFormStatus);

export const selectBeforeEditData = createSelector([companyAuthorizationListSlice], (slice) => slice.beforeEditData);
export const selectBeforeEditDataAuthType = createSelector([companyAuthorizationListSlice], (slice) => slice.beforeEditDataAuthType);

export const selectRemovePopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.removePopupVisible);
export const selectAuthTypePopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.authTypePopupVisible);
export const selectRemoveAuthTypePopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.removeAuthTypePopupVisible);
export const selectAddAuthTypePopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.addAuthTypePopupVisible);

export const selectAddPopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.addPopupVisible);

export const selectPersonList = createSelector([companyAuthorizationListSlice], (slice) => slice.personList);

export const selectMasterList = createSelector([companyAuthorizationListSlice], (slice) => slice.masterList);
export const selectMasterItem = createSelector([companyAuthorizationListSlice], (slice) => slice.selectedMasterList);
export const selectCompanyState = createSelector([companyAuthorizationListSlice], (slice) => slice.companyState);

export const selectAuthTypeList = createSelector([companyAuthorizationListSlice], (slice) => slice.authTypeList);
export const selectSelectedAuthType = createSelector([companyAuthorizationListSlice], (slice) => slice.selectedAuthType);
export const selectAddedCompany = createSelector([companyAuthorizationListSlice], (slice) => slice.addedCompany);
export const selectAddingCompanyInProgress = createSelector([companyAuthorizationListSlice], (slice) => slice.addingCompanyInProgress);
export const selectPopupVisible = createSelector([companyAuthorizationListSlice], (slice) => slice.popupVisible);

export const selectFilterAuthTypes = createSelector([companyAuthorizationListSlice], (slice) => slice.filterAuthTypes);
export const selectSearchText = createSelector([companyAuthorizationListSlice], (slice) => slice.searchText);

export const submittingFormSuccess = createSelector([companyAuthorizationListSlice], (slice) => slice.submittingFormSuccess);
