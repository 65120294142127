import { call, put, all, takeLatest } from 'redux-saga/effects';
import { clientPersonnelActions } from './clientPersonnelSlice';
import { fetchPersonnelAuthorizedLocations, fetchClientPersonnelDetailed, fetchClientPersonnelById } from 'services/clientPersonnelService';
import { getAllOrganizationList } from 'services/companyAuthorizedService';
import { showNotification } from 'utils/notificationsHandler';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';

export function* fetchPesonnelAuthorizedLocations(action) {
    const { personnelId } = action.payload;
    try {
        const data = yield call(fetchPersonnelAuthorizedLocations, personnelId);
        yield put(clientPersonnelActions.fetchAuthorizedLocations(data));
    } catch (error) {
        yield put(clientPersonnelActions.fetchAuthorizedLocationsError(error.message));
    }
}

function* fetchOrganizationInfo() {
    try {
        const response = yield call(getAllOrganizationList);
        yield put(clientPersonnelActions.fetchOrganizationInfoSuccess(response));
    } catch (error) {
        console.error('Error fetching organization list', error);
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        showNotification({ title: formattedError });
        yield put(clientPersonnelActions.fetchOrganizationInfoError());
    }
}

function* fetchClientPersonnelTableInfo(action) {
    try {
        const { page, size, filters } = action.payload;
        const response = yield call(fetchClientPersonnelDetailed, { page, size, filters });
        const { values, elementsPerPage, totalElements, totalPages } = response;
        yield put(clientPersonnelActions.setPaginationInfo({ elementsPerPage, totalElements, totalPages }));
        yield put(clientPersonnelActions.fetchClientPersonnelTableInfoSuccess(values));
    } catch (error) {
        console.error('Error fetching client personnel', error);
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        showNotification({ title: formattedError });
        yield put(clientPersonnelActions.fetchClientPersonnelTableInfoError());
    }
}

function* fetchClientPersonnelInfo(action) {
    const { personnelId } = action.payload;
    try {
        const response = yield call(fetchClientPersonnelById, personnelId);
        yield put(clientPersonnelActions.fetchClientPersonnelInfoSuccess(response));
    } catch (error) {
        console.error('Error fetching client personnel by id', error);
        yield put(clientPersonnelActions.fetchClientPersonnelInfoError(error.message));
    }
}

export function* onFetchPersonnelAuthorizedLocations() {
    yield takeLatest(clientPersonnelActions.fetchAuthorizedLocationsRequest.type, fetchPesonnelAuthorizedLocations);
}

export function* onFetchOrganizationInfo() {
    yield takeLatest(clientPersonnelActions.fetchOrganizationInfo.type, fetchOrganizationInfo);
}

export function* onFetchClientPersonnelById() {
    yield takeLatest(clientPersonnelActions.fetchClientPersonnelByIdRequest.type, fetchClientPersonnelInfo);
}

export function* onFetchClientPersonnelTableData() {
    yield takeLatest(clientPersonnelActions.fetchClientPersonnelTableInfoRequest.type, fetchClientPersonnelTableInfo);
}

export function* clientPersonnelSaga() {
    yield all([
        call(onFetchPersonnelAuthorizedLocations),
        call(onFetchOrganizationInfo),
        call(onFetchClientPersonnelById),
        call(onFetchClientPersonnelTableData),
    ]);
}

export default clientPersonnelSaga;
