import { axiosLasta } from 'config';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { submitForm } from 'store/monitoringObject/sagas/submitMonitoringObjectFormSaga';
import notificationsHandler from 'utils/notificationsHandler';
import { throttleAction } from 'utils/sagaHelpers';
import { monitoringObjectActions } from '../monitoringObjectSlice';
import { fetchLocationMonitoringObjects } from 'services/monitoringService';

export function* fetchTableValues({ payload: { id, monitoringObjectActiveParam: active } }) {
    try {
        const data = yield call(fetchLocationMonitoringObjects, id, active);
        yield put(monitoringObjectActions.setTableValues(data));
    } catch (err) {
        yield notificationsHandler({
            title: `Error fetching monitoring objects`,
            err,
        });
    }
}

export function* onfetchTableValues() {
    yield throttleAction(monitoringObjectActions.fetchTableValues.type, fetchTableValues);
}

export function* fetchLocations() {
    try {
        const response = yield axiosLasta(`/location/all`);
        const { data } = response;
        yield put(monitoringObjectActions.setFetchedType(data));
    } catch (err) {
        yield notificationsHandler({ title: `Error fetching locations`, err });
    }
}

export function* fetchSingleMonitoringObject({ payload }) {
    try {
        const response = yield axiosLasta(`/monitoring/object/id/${payload}`);
        const { data } = response;
        const { location, ...monitoringObject } = data;
        yield put(monitoringObjectActions.setSelectedMonitoringObject(monitoringObject));
    } catch (err) {
        yield notificationsHandler({ title: `Error fetching monitoring object`, err });
    }
}

export function* onFetchLocations() {
    yield takeEvery(monitoringObjectActions.fetchLocation.type, fetchLocations);
}
export function* onFetchSingleMonitoringObject() {
    yield takeEvery(monitoringObjectActions.fetchSingleMonitoringObject.type, fetchSingleMonitoringObject);
}
export function* onSubmitFormStart() {
    yield takeEvery(monitoringObjectActions.submitFormStart.type, submitForm);
}

export function* monitoringObjectSaga() {
    yield all([call(onfetchTableValues), call(onFetchLocations), call(onSubmitFormStart), call(onFetchSingleMonitoringObject)]);
}
