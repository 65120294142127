import { useRouteHandleData } from 'components/appRoute/hooks/useRouteHandleData.js';

export const useTopNavRouteData = () => {
    const routeData = useRouteHandleData();
    const { topNavMiddleAdornment, helpDialogComponent, pageTitle, featureName } = routeData || {};

    return {
        topNavMiddleAdornment,
        helpDialogComponent,
        pageTitle,
        featureName,
    };
};
