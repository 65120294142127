import { useSelector } from 'react-redux';
import { selectUser, selectIsUserDataRequested } from 'store/auth/authSelectors';
import { useCallback, useMemo } from 'react';
import { ROLES } from 'utils/constants';

const useAuthUser = () => {
    const user = useSelector(selectUser);
    const isUserDataRequested = useSelector(selectIsUserDataRequested);

    const userHasRoles = useCallback(
        (roles) => {
            if (!user) {
                return false;
            }
            return user?.roles?.some((role) => roles.includes(role));
        },
        [user]
    );

    const userHasAdminRoles = useMemo(() => userHasRoles([ROLES.ADMIN]), [userHasRoles]);

    return {
        user,
        userHasRoles,
        userHasAdminRoles,
        isUserDataRequested,
    };
};

export default useAuthUser;
