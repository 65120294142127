import { differenceInMinutes, endOfDay, format, isToday, isTomorrow, isValid, isYesterday, parseISO, startOfDay } from 'date-fns';
export const DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm a';
export const DATE_TIME_FORMAT_WITH_SECONDS = 'MM/dd/yyyy hh:mm:ss a';
export const DATE_TIME_LONG_FORMAT = 'd MMMM yyyy hh:mm a';
export const TIME_FORMAT = 'HH:mm:ss';
export const TIME_FORMAT_WITHOUT_SECONDS = 'hh:mm a';
export const FULL_MONTH_DAY_YEAR_FORMAT = 'MMM dd, yyyy';
export const DATE_FORMAT_DATE_OF_BIRTH = 'yyyy-MM-dd';
export const DEFAULT_START_TIME = startOfDay(new Date());
export const DEFAULT_END_TIME = endOfDay(new Date());

export const fromIsoString = (isoString) => {
    if (!isoString) return null;
    const date = new Date(isoString);
    return isValid(date) ? date : null;
};

export const isValidDate = (date) => {
    return isValid(date);
};

export const formatDate = (date, dateFormat = DATE_FORMAT) => {
    if (!date) {
        return '';
    }
    return format(date, dateFormat);
};

/**
 * Formats a timestamp into a specified date format using date-fns.
 *
 * @param {number} dateTime - The datetime to format. Can be a timestamp or a Date object.
 * @param {string} [dateFormat='MM/dd/yyyy hh:mm a'] - The output format string. Defaults to `'MM/dd/yyyy hh:mm a'`.
 * @returns {string} - The formatted date or an empty string if the timestamp is invalid.
 *
 * @example
 * const timestamp = 1735160700000; // '2024-11-25T15:45:00Z'
 * const timestamp = new Date('2024-11-25T15:45:00Z');
 * console.log(formatDateTime(timestamp)); // Output: '11/25/2024 03:45 PM'
 * console.log(formatDateTime(timestamp, 'yyyy-MM-dd HH:mm')); // Output: '2024-11-25 15:45'
 * console.log(formatDateTime('invalid')); // Output: ''
 */
export const formatDateTime = (dateTime, dateFormat = 'MM/dd/yyyy hh:mm a') => {
    if (!isValid(dateTime)) {
        return '';
    }
    return format(dateTime, dateFormat);
};

export const timeDifferentFriendlyName = (dateTime) => {
    const targetDate = new Date(dateTime);
    const now = new Date();

    const timeDifference = targetDate - now;

    if (timeDifference <= 0) {
        return '';
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    if (days === 1) return `Tomorrow ${formatDate(dateTime, 'hh:mma')}`;
    else if (days === 0) {
        const hourText = hours === 0 ? '' : hours === 1 ? `${hours} hour ` : `${hours} hours `;
        const minuteText = minutes === 1 ? `${minutes} minute left` : `${minutes} minutes left`;
        return hourText + minuteText;
    } else return formatDate(dateTime, 'd MMMM, yyyy, hh:mma');
};

export const formatTime = (time) => {
    return time.slice(0, 5).replace(':', '.');
};

export const generateDefaultTimeRestrictions = () => {
    const start = DEFAULT_START_TIME;
    const end = DEFAULT_END_TIME;

    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

    const timeRestrictions = days.reduce((acc, day) => {
        acc[day] = { start, end, checked: true };
        return acc;
    }, {});

    return { timeRestrictions, start, end };
};

export const calculateDuration = (start, end) => {
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : null;

    if (isNaN(startDate) || (end && isNaN(endDate))) {
        throw new Error('Invalid date provided.');
    }

    if (!endDate) {
        return null;
    }

    startDate.setSeconds(0, 0);
    endDate.setSeconds(0, 0);

    return Math.abs(differenceInMinutes(endDate, startDate));
};

const formatSmartDate = (utcDateString) => {
    if (!utcDateString) return '';

    const date = fromIsoString(utcDateString);

    if (!isValid(date)) return '';

    let formattedDate = '';
    let formattedTime = formatDate(date, TIME_FORMAT_WITHOUT_SECONDS);

    if (isToday(date)) {
        formattedDate = 'Today';
    } else if (isYesterday(date)) {
        formattedDate = 'Yesterday';
    } else if (isTomorrow(date)) {
        formattedDate = 'Tomorrow';
    } else {
        formattedDate = formatDate(date, DATE_FORMAT);
    }

    return {
        date: formattedDate,
        time: formattedTime,
    };
};

export default formatSmartDate;
