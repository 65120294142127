import { Select, styled, Tabs } from '@mui/material';

export const TabsStyled = styled(Tabs)(({ helpSection }) => ({
    minHeight: '4.5rem',
    '& .MuiTab-root': {
        width: 200,
        margin: helpSection ? '.5rem 0' : '.5rem',
        color: 'var(--color-black)',
        textTransform: 'capitalize',
        borderRadius: '.5rem',
        boxShadow: '0 0 .5rem 0 #304DCA40',
        '&.Mui-selected': {
            backgroundColor: 'var(--color-blue)',
            color: 'var(--color-white)',
        },
    },
}));

export const SelectStyled = styled(Select)({
    backgroundColor: 'var(--color-white)',
});
