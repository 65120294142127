import { createSelector } from '@reduxjs/toolkit';

export const relaysSlice = (state) => state.relays;

export const selectAuthoriziedfetching = createSelector([relaysSlice], (slice) => slice.submittingFormInProgress);
export const selectAuthoriziedErrors = createSelector([relaysSlice], (slice) => slice.submittingFormError);
export const selectAuthoriziedItemFormStatus = createSelector([relaysSlice], (slice) => slice.formStatus);
export const selectBeforeEditData = createSelector([relaysSlice], (slice) => slice.beforeEditData);
export const selectRemovePopupVisible = createSelector([relaysSlice], (slice) => slice.removePopupVisible);
export const selectAddPopupVisible = createSelector([relaysSlice], (slice) => slice.addPopupVisible);
export const selectMasterList = createSelector([relaysSlice], (slice) => slice.masterList);
export const selectMasterListItem = createSelector([relaysSlice], (slice) => slice.selectedMasterList);
export const singleRelayMoniotoringObjects = createSelector([relaysSlice], (slice) => slice.singleRelayMoniotoringObjects);
export const selectSingleRelay = createSelector([relaysSlice], (slice) => slice.singleRelay);
export const rowsPerPage = createSelector([relaysSlice], (slice) => slice.rowsPerPage);
export const selectPopupVisible = createSelector([relaysSlice], (slice) => slice.popupVisible);
export const totalElements = createSelector([relaysSlice], (slice) => slice.totalElements);
