import { List, ListItemText, Typography } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';

const OverviewSection = () => {
    return (
        <>
            <Typography variant="h4">OVERVIEW</Typography>
            <Typography classes={styles['description']}>
                Inventory lists vehicles or other kinds of equipment that are in a specific Location. The list of vehicles or equipment can be:
            </Typography>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Currently in the Location at the time this page was accessed (a.k.a. <b>Current Inventory</b>).
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Previously in the Location at a specific date and time (a.k.a. <b>Point-In-Time Inventory</b>).
                    </span>
                </ListItemText>
            </List>
            <Typography className={styles['description']}>The Inventory List also provides features for privileged users to manually:</Typography>
            <List className={styles['unordered-list']}>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Create a new asset in the <b>Current Inventory</b> of the selected Location.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Update a specific asset in the <b>Current Inventory</b> of the selected Location.
                    </span>
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    <span>
                        Remove a specific asset from the <b>Current Inventory</b> of the selected Location.
                    </span>
                </ListItemText>
            </List>
        </>
    );
};

export default OverviewSection;
