import {
    selectFetchingLocationsError,
    selectFetchingLocationsInProgress,
    selectLocationsList,
    selectSelectedLocation,
    selectSelectedLocationId,
} from 'components/globalLocationSelector/store/globalLocationSelectors.js';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SESSION_STORAGE_KEY } from 'utils/constants';
import { fetchLocationsStart, setSelectedLocationId } from '../store/globalLocationSlice';

const useGlobalLocation = () => {
    const dispatch = useDispatch();
    const sessionStorageLocationId = sessionStorage.getItem(SESSION_STORAGE_KEY.LOCATION_ID);

    const dispatchFetchLocations = useCallback(() => {
        dispatch(fetchLocationsStart());
    }, [dispatch]);

    const dispatchSetSelectLocationId = useCallback(
        (locationId) => {
            dispatch(setSelectedLocationId(locationId));
            sessionStorage.setItem(SESSION_STORAGE_KEY.LOCATION_ID, locationId || '');
        },
        [dispatch]
    );

    const fetchingLocationsInProgress = useSelector(selectFetchingLocationsInProgress);
    const fetchingLocationsError = useSelector(selectFetchingLocationsError);
    const locationsList = useSelector(selectLocationsList);
    const selectedLocationId = useSelector(selectSelectedLocationId);
    const selectedLocation = useSelector(selectSelectedLocation);

    const locationId = useMemo(() => {
        return selectedLocationId || sessionStorageLocationId;
    }, [selectedLocationId, sessionStorageLocationId]);

    return {
        fetchLocations: dispatchFetchLocations,
        setSelectLocationId: dispatchSetSelectLocationId,
        fetchingLocationsInProgress,
        fetchingLocationsError,
        locationsList,
        selectedLocationId: locationId,
        selectedLocation,
    };
};

export default useGlobalLocation;
