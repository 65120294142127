import { Box, Divider, Stack } from '@mui/material';
import styles from 'features/DefaultHelp.module.css';
import ControlsSection from './Controls';
import InstructionsSection from './Instructions';
import OverviewSection from './Overview';
import { Footer, FooterText } from 'components/help/HelpContainerStyled';
import { Link } from 'react-router-dom';

const HelpDialog = () => {
    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                <OverviewSection />
                <Divider className={styles['divider']} />
                <ControlsSection />
                <Divider className={styles['divider']} />
                <InstructionsSection />
            </Box>
            <Footer>
                <FooterText>
                    {`For further support, email us at `}
                    <Link href="mailto:cs@birdseye.ca" underline="none">
                        cs@birdseye.ca
                    </Link>
                    {'.'}
                </FooterText>
            </Footer>
        </Stack>
    );
};

export default HelpDialog;
