import { all, call } from 'redux-saga/effects';
import { employeeSaga } from './employees/employeeSaga';
import { peopleAuthorizedListSaga } from './peopleAuthorizedList/peopleAuthorizedListSagas';
import { companyAuthorizationSaga } from './companyAuthorizedList/companyAuthorizationListSaga';
import { tableControlSaga } from './tableControlSlice/tableControlSagas';
import { cameraInfoSaga } from './cameraInfo/cameraInfoSaga';
import { organizationListSaga } from './organizationList/organizationListSaga';
import { locationListSaga } from './locationList/locationListSaga';
import { divisionListSaga } from './divisionList/divisionListSaga';
import { monitoringElementSaga } from './MonitoringElement/monitoringElementSaga';
import { controlCentarSaga } from './controlCentar/controlCentarSaga';
import { monitoringObjectSaga } from './monitoringObject/sagas/monitoringObjectSaga';
import { authSaga } from './auth/authSagas';
import { globalLocationSaga } from 'components/globalLocationSelector/store/globalLocationSaga.js';
import { accessPointRecordsSaga } from '../components/accessPointRecords/store/accessPointRecordsSaga';
import { userLocationsSaga } from '../features/userLocations/store/userLocationsSaga';
import { featureFlagSaga } from 'store/featureFlag/featureFlagSaga';
import { audioConfigurationSaga } from 'features/audioConfiguration/store/AudioConfigurationSagas';
import { relaysSaga } from './relays/relaysListSaga';
import { clientPersonnelSaga } from '../features/clientPersonnel/store/clientPersonnelSaga';

export default function* rootSaga() {
    yield all([
        call(employeeSaga),
        call(peopleAuthorizedListSaga),
        call(tableControlSaga),
        call(authSaga),
        call(companyAuthorizationSaga),
        call(cameraInfoSaga),
        call(organizationListSaga),
        call(locationListSaga),
        call(divisionListSaga),
        call(monitoringElementSaga),
        call(controlCentarSaga),
        call(monitoringObjectSaga),
        call(globalLocationSaga),
        call(accessPointRecordsSaga),
        call(userLocationsSaga),
        call(featureFlagSaga),
        call(audioConfigurationSaga),
        call(relaysSaga),
        call(clientPersonnelSaga),
    ]);
}
