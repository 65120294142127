import { useCallback, useState } from 'react';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import { showNotification } from 'utils/notificationsHandler';

export const useMutation = ({ mutationFn, onSuccess, onFail, onSettle }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const mutate = useCallback(
        async (...args) => {
            setIsLoading(true);
            setError(null);
            try {
                const result = await mutationFn(...args);
                onSuccess?.();
                setError(null);
                return result;
            } catch (error) {
                onFail?.();
                setError(error);
                const formattedError = getFormattedErrorMessageFromErrorObject(error);
                showNotification({ title: formattedError });
            } finally {
                onSettle?.();
                setIsLoading(false);
            }
        },
        [mutationFn, onFail, onSettle, onSuccess]
    );

    return { mutate, isLoading, error };
};
