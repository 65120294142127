import { selectSelectedLocationId } from 'components/globalLocationSelector/store/globalLocationSelectors.js';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { fetchAllLocations, fetchUserLocationForCurrentUser } from 'services/userLocationService.js';
import { selectUser } from 'store/auth/authSelectors';
import { ROLES, SESSION_STORAGE_KEY } from 'utils/constants.js';
import { getFormattedErrorMessageFromErrorObject } from 'utils/formatErrorCode';
import notificationsHandler from 'utils/notificationsHandler';
import { fetchLocationsError, fetchLocationsStart, fetchLocationsSuccess, setSelectedLocationId } from './globalLocationSlice';

// Worker saga: will be fired on fetchLocationsStart actions
function* fetchLocations() {
    try {
        const user = yield select(selectUser);
        const isLoadAllLocations =
            user?.roles?.includes(ROLES.ADMIN) || user?.roles?.includes(ROLES.AGENT) || user?.roles?.includes(ROLES.TEAM_LEADER);
        const isOnlyRoleUser = user?.roles?.length === 1 && user?.roles?.includes(ROLES.USER);
        let sortedData = [];
        if (isOnlyRoleUser) {
            yield put(fetchLocationsSuccess(sortedData));
            yield call(setDefaultSelectedLocation, sortedData);
            return;
        }
        const data = isLoadAllLocations ? yield fetchAllLocations() : yield fetchUserLocationForCurrentUser();
        sortedData = data?.sort((a, b) => a.yardName?.localeCompare(b.yardName));
        yield put(fetchLocationsSuccess(sortedData));
        yield call(setDefaultSelectedLocation, sortedData);
    } catch (error) {
        yield put(fetchLocationsError(error.message));
        const formattedError = getFormattedErrorMessageFromErrorObject(error);
        yield notificationsHandler({ title: formattedError });
    }
}

function* setDefaultSelectedLocation(locationList) {
    const sessionStorageLocation = Number(sessionStorage.getItem(SESSION_STORAGE_KEY.LOCATION_ID));
    const selectedLocationId = yield select(selectSelectedLocationId);
    if (locationList?.length && !selectedLocationId) {
        const firstLocation = locationList[0];
        const defaultLocation = locationList?.includes(sessionStorageLocation) ? sessionStorageLocation : firstLocation.id;
        yield put(setSelectedLocationId(defaultLocation));
        sessionStorage.setItem(SESSION_STORAGE_KEY.LOCATION_ID, defaultLocation);
        return;
    }
    const selectedLocation = locationList.find((location) => location.id === selectedLocationId);
    // The selected location is not in the list of locations
    //  => reset selected location to null
    if (!selectedLocation) {
        yield put(setSelectedLocationId(null));
    }
}

// Watcher saga: spawns a new fetchLocations task on each fetchLocationsStart
function* onFetchLocationsStart() {
    yield takeLatest(fetchLocationsStart.type, fetchLocations);
}

// Root saga: combines all the watcher sagas
export function* globalLocationSaga() {
    yield all([call(onFetchLocationsStart)]);
}
