import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from 'features/DefaultHelp.module.css';
import { AuthTypeSwitch } from 'features/authorizedList/sidePanel/SidePanelStyled';
import useAuthUser from 'hooks/useAuthUser';
import PropTypes from 'prop-types';
import { useState } from 'react';

const HelpDialog = () => {
    const { userHasAdminRoles } = useAuthUser();
    const interactions = [
        { key: 'search', title: 'Users Search' },
        { key: 'filter', title: 'Users Status Filter' },
        { key: 'invite', title: 'Invite New User' },
        { key: 'update', title: 'Update Existing User' },
        { key: 'activate', title: 'Activate / Deactivate User' },
    ];

    const [selectedInteraction, setSelectedInteraction] = useState(interactions[0].key);

    const getInteractionComponent = (key) => {
        switch (key) {
            case 'filter':
                return renderUsersStatusFilter();
            case 'invite':
                return renderInviteNewUser();
            case 'update':
                return renderUpdateExistingUser();
            case 'activate':
                return renderActivateDeactivateUser();
            default:
                return renderUsersSearch();
        }
    };

    const renderUsersSearch = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Users Search</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>Type keywords into the Search box.</ListItemText>
                <ListItemText className={styles['list-item']}>Press Enter to start searching.</ListItemText>
                <ListItemText className={styles['list-item']}>
                    The Users Listing will narrow down to display only Users that match the entered keywords.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    A message will appear if no matching Users are found in the viewing Users Listing.
                </ListItemText>
                <ListItemText className={styles['list-item']}>Empty Search box shall reset to display all Users.</ListItemText>
                <ListItemText className={styles['list-item']}>The result shall be combined with the Status Filter.</ListItemText>
            </List>
        </Box>
    );

    const renderUsersStatusFilter = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Users Status Filter</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>Select status to filter.</ListItemText>
                <ListItemText className={styles['list-item']}>
                    The Users Listing will narrow down to display only Users have selected status.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    A message will appear if no matching User are found in the viewing Users Listing.
                </ListItemText>
                <ListItemText className={styles['list-item']}>The result shall be combined with Users Search.</ListItemText>
            </List>
        </Box>
    );

    const renderInviteNewUser = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Invite New User</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    Click on <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} /> to open <b>Invite User</b> modal
                    form.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    Enter Invitee Email, the email should not be existing in system and not Internal Users of Birdseye.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    Username shall be populated automatically from Email. You can change it. Username must be unique.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    Select one or many Organizations in Authorized Organizations. You must select at least 01 Organization to submit this form.
                    Selected Organizations can be removed by clicking on <b>x</b> in tags.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    For each selected Organization, list of available Locations shall be displayed underneath, you can toggle ON / OFF Organizations
                    or Locations.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    Locations with toggle ON can be expanded to display list of Features and Permission settings:
                </ListItemText>
                <ListItemText className={styles['list-item']}>Check on Permissions will grant User such Permissions.</ListItemText>
                <ListItemText className={styles['list-item']}>
                    Granting some specific Features will auto-grant other dependent Features as well, this is to ensure the accessibility of Users.
                    Dependent Features checked by this mechanism cannot be unchecked unless the main Feature was unchecked.
                </ListItemText>
                <ListItemText className={styles['list-item']}>Display Name is optional and will be used to display in changes log.</ListItemText>
                <ListItemText className={styles['list-item']}>
                    Permissions Preset provides an auto-check preset to speed up Permission granting.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    You can also copy Permission Settings to other Locations with toggle ON in the same Organization or across Organizations.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    After finishing, click on <b>[Invite]</b> button to start inviting User. Invited User shall receive email with credentials to
                    start using Lasta with authorized Locations and granted Permissions.
                </ListItemText>
            </List>
        </Box>
    );

    const renderUpdateExistingUser = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Update Existing User</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    Open existing User in Users Listing by double click on an User or select View option after clicking{' '}
                    <MoreVertIcon className={styles['inlined-icon']} /> in Option column.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    A modal form shall open with all User information, authorized Locations and Permissions.
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    Email and Username cannot be changed. But you can
                    <br /> a. Change the Active toggle
                    <br /> b. Change Organizations
                    <br /> c. Change Locations
                    <br /> d. Change Permission in each Locations
                </ListItemText>
                <ListItemText className={styles['list-item']}>
                    After updating, click on <b>[Save]</b> button to submit changes. User shall receive email about this update.
                </ListItemText>
            </List>
        </Box>
    );

    const renderActivateDeactivateUser = () => (
        <Box className={styles['instruction-child-container']}>
            <p className={styles['instruction-header']}>Activate / Deactivate User</p>
            <List className={styles['ordered-list']}>
                <ListItemText className={styles['list-item']}>
                    Open existing User in Users Listing by double click and change the Active toggle and click on <b>[Save]</b> button or select
                    Deactivate / Activate after clicking <MoreVertIcon className={styles['inlined-icon']} /> in Option column.
                </ListItemText>
                <ListItemText className={styles['list-item']}>User shall receive email about this update.</ListItemText>
            </List>
        </Box>
    );

    const renderOverviewSection = () => (
        <>
            <h4>OVERVIEW</h4>
            {userHasAdminRoles ? (
                <>
                    <p className={styles['description']}>
                        The Users and Permissions feature allows Birdseye Admin to manage all existing External Users, this includes:
                    </p>
                    <List className={styles['unordered-list']}>
                        <ListItemText className={styles['list-item']}>
                            Invite new External Users, authorize Locations and grant Permissions on each feature in each Location.
                        </ListItemText>
                        <ListItemText className={styles['list-item']}>
                            Update existing External Users to change their authorized Locations or Permissions settings.
                        </ListItemText>
                        <ListItemText className={styles['list-item']}>
                            Revoke or grant External Users access to Lasta via deactivate or activate their account.
                        </ListItemText>
                    </List>
                </>
            ) : (
                <>
                    <p className={styles['description']}>
                        The Users and Permissions feature allows Users with efficient privilege to manage all other Users that was assigned and
                        granted Permissions to Locations that under managing User’s authorized Locations, this includes:
                    </p>
                    <List className={styles['unordered-list']}>
                        <ListItemText className={styles['list-item']}>
                            Invite new Users, authorize Locations and grant Permissions on each feature in each Location.
                        </ListItemText>
                        <ListItemText className={styles['list-item']}>
                            Update existing Users to change their authorized Locations or Permissions settings.
                        </ListItemText>
                    </List>
                </>
            )}
        </>
    );

    const renderControlsSection = () => (
        <>
            <h4>CONTROLS</h4>
            <Table className={styles['controls-table']}>
                <TableHead>
                    <TableRow>
                        <TableCell>Actions</TableCell>
                        <TableCell>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>
                            <span>Invite User</span>
                            <span>
                                <AddCircleOutlinedIcon className={`${styles['add-icon']} ${styles['inlined-icon']}`} />
                            </span>
                        </TableCell>
                        <TableCell>Invite new user</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Search Box</span>
                            <Stack paddingTop={2}>
                                <TextField disabled placeholder="Search..." variant="outlined" size="small" />
                            </Stack>
                        </TableCell>
                        <TableCell>
                            Enter keywords to search for specific Users in Users Listing. <br />
                            The search is performed globally across multiple columns.
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Status Filter</span>
                            <AuthTypeSwitch checked />
                        </TableCell>
                        <TableCell>
                            Filter Users Listing to display: <br />
                            <List className={styles['unordered-list']}>
                                <ListItemText className={styles['list-item']}>
                                    <b>All Statuses:</b> All Users regardless of status
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Active:</b> Only Users with Active status
                                </ListItemText>
                                <ListItemText className={styles['list-item']}>
                                    <b>Inactive:</b> Only Users with Inactive status
                                </ListItemText>
                            </List>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>
                            <span>Action Options Menu</span>
                        </TableCell>
                        <TableCell>Open available options to interact with a specific User</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </>
    );

    const renderInstructionsSection = () => (
        <>
            <h4>INTERACTIONS</h4>
            <Box display="flex" flexDirection="row" boxSizing="border-box" height="16rem" width="100%">
                <Box className={styles['instruction-title-container']}>
                    <Stack spacing={2}>
                        {interactions.map((interaction) => (
                            <Button
                                key={interaction.key}
                                variant="outlined"
                                size="small"
                                className={`${styles['instruction-button']} 
                                    ${selectedInteraction === interaction.key ? styles['instruction-button--selected'] : ''}`}
                                disableRipple
                                onClick={() => setSelectedInteraction(interaction.key)}
                            >
                                {interaction.title}
                                <ArrowForwardIosRoundedIcon className={styles['arrow-right']} />
                            </Button>
                        ))}
                    </Stack>
                </Box>
                <Box className={styles['instruction-content-container']}>{getInteractionComponent(selectedInteraction)}</Box>
            </Box>
        </>
    );

    return (
        <Stack className={styles['help-dialog-container']}>
            <Box className={styles['help-dialog-content-container']}>
                {renderOverviewSection()}
                <Divider className={styles['divider']} />
                {renderControlsSection()}
                <Divider className={styles['divider']} />
                {renderInstructionsSection()}
            </Box>
        </Stack>
    );
};

export default HelpDialog;

HelpDialog.propTypes = {
    roles: PropTypes.arrayOf(PropTypes.string),
};
