import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    allLocations: [],
    selectedLocation: null,

    submittingFormInProgress: false,
    submittingFormError: null,
    submittingFormSuccess: false,

    authorizedPeople: [],
    isFetchingAuthorizedPeople: false,
    selectedAuthorizedPerson: null,

    authorizedPersonAlerts: [],
    selectedPersonAlert: null,

    formStatus: 'viewOnly',
    authTypeFormstatus: 'viewOnly',
    beforeEditData: null,
    beforeEditDataAuthType: null,

    removePopupVisible: false,
    addPopupVisible: false,
    authTypePopupVisible: false,
    removeAuthTypePopupVisible: false,
    addAuthTypePopupVisible: false,

    personList: [],
    selectedPerson: null,

    authTypeList: [],
    addedCompany: false,
    addingPersonInProgress: false,
    popupVisible: false,
    searchText: null,
    filterAuthTypes: [],
};

const peopleAuthorizedListSlice = createSlice({
    name: 'peopleAuthorizedList',
    initialState,
    reducers: {
        fetchAllLocations() {},
        setAllLocations(state, { payload: allLocations }) {
            state.allLocations = allLocations;
        },
        fetchAuthorizedPersonPerLocation(state) {
            state.submittingFormError = null;
            state.isFetchingAuthorizedPeople = true;
        },
        setSelectedLocation(state, { payload: selectedLocation }) {
            state.selectedLocation = selectedLocation;
        },
        fetchAuthorizedLocationSuccess(state, { payload: authorizedPeople }) {
            state.authorizedPeople = authorizedPeople;
            state.isFetchingAuthorizedPeople = false;
        },

        submitFormStart(state) {
            state.submittingFormInProgress = true;
            state.submittingFormError = null;
            state.submittingFormSuccess = false;
        },
        submitFormFailed(state, { payload: error }) {
            state.submittingFormInProgress = false;
            state.submittingFormError = error;
            state.isFetchingAuthorizedPeople = false;
        },
        submitFormSuccess(state, { payload: _ }) {
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.submittingFormSuccess = true;
        },
        fetchPersonAuthTypesStart() {},
        fetchAuthorizedPersonAlerts(state, { payload: authTypeList }) {
            state.authorizedPersonAlerts = authTypeList;
        },
        resetAuthPersonAlerts(state) {
            state.authorizedPersonAlerts = [];
        },
        setSelectedAuthorizedPerson(state, { payload }) {
            state.selectedAuthorizedPerson = payload
                ? [...state.authorizedPeople, ...state.personList].find((person) => person.id === payload)
                : null;
        },
        setSelectedAuthorizedPersonDetail(state, { payload }) {
            state.selectedAuthorizedPerson = payload;
            state.beforeEditData = payload;
        },
        setSelectedAlert(state, { payload }) {
            state.selectedPersonAlert = payload ? state.authorizedPersonAlerts.find((alert) => alert.id === payload) : null;
        },
        setFormStatus(state, { payload: formStatus }) {
            state.formStatus = formStatus;
        },
        setAuthTypeFormstatus(state, { payload: authTypeFormstatus }) {
            state.authTypeFormstatus = authTypeFormstatus;
        },
        setBeforeEditData(state, { payload: personData = null }) {
            state.beforeEditData = personData;
        },
        setBeforeEditDataAuthType(state, { payload: personAlert = null }) {
            state.beforeEditDataAuthType = personAlert;
        },
        resetAuthorizedPeopleState(state) {
            state.selectedLocation = null;
            state.submittingFormInProgress = false;
            state.submittingFormError = null;
            state.submittingFormSuccess = false;
            state.formStatus = 'viewOnly';
            state.authorizedPeople = [];
            state.selectedAuthorizedPerson = null;
            state.authorizedPersonAlerts = [];
            state.selectedPersonAlert = null;
            state.beforeEditData = null;
        },
        setRemovePopupVisible(state, { payload }) {
            state.removePopupVisible = payload;
        },
        setAuthTypePopupVisible(state, { payload: visible }) {
            state.authTypePopupVisible = visible;
        },
        setRemoveAuthTypePopupVisible(state, { payload }) {
            state.removeAuthTypePopupVisible = payload;
        },
        setAddAuthTypePopupVisible(state, { payload }) {
            state.addAuthTypePopupVisible = payload;
        },
        setAddPopupVisible(state, { payload }) {
            state.addPopupVisible = payload;
        },
        fetchPersonStart(state) {
            state.fetchingPersonInprogress = true;
        },
        addAuthorizedPerson() {},
        addPerson(state) {
            state.submittingFormInProgress = true;
            state.submittingFormSuccess = false;
        },
        setSelectedPerson(state, { payload }) {
            state.selectedPerson = payload ? state.personList.find((person) => person.id === payload) : null;
        },
        fetchPersonError(state, { payload: error }) {
            state.fetchingPersonInprogress = false;
            state.fetchingPersonError = error;
        },

        fetchPersonSuccess(state, { payload: personList }) {
            state.personList = personList;
            state.fetchingPersonInprogress = false;
        },
        setAuthTypeList(state, { payload: authTypes }) {
            state.authTypeList = authTypes;
        },
        fetchAuthTypeList() {},
        deleteAuthPerson(state) {
            state.submittingFormSuccess = false;
        },
        deleteSuccess(state) {
            state.submittingFormSuccess = true;
        },
        deleteAuthType() {},
        setAddedCompany(state, { payload: boolean }) {
            state.addedCompany = boolean;
        },
        setAddingPersonInProgress(state, { payload: boolean }) {
            state.addingPersonInProgress = boolean;
        },
        resetItemsAuthorizations(state) {
            state.authorizedPersonAlerts = [];
        },
        setPopupVisible(state, { payload: visible }) {
            state.popupVisible = visible;
        },
        setSearchText(state, { payload: text }) {
            state.searchText = text;
        },
        setFilterAuthTypes(state, { payload: filterAuthTypes }) {
            state.filterAuthTypes = filterAuthTypes;
        },
    },
});

export const peopleAuthorizedListActions = peopleAuthorizedListSlice.actions;
export const peopleAuthorizedListReducer = peopleAuthorizedListSlice.reducer;
