import NavigationDrawer from 'components/navigation/NavigationDrawer.jsx';
import CircularLoader from 'components/UI/loaders/CircularLoader.jsx';
import { CONTROL_CENTAR_WEBSOKET } from 'config';
import { PermissionsProvider } from 'contexts/PermissionsContext';
import PropTypes from 'prop-types';
import { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { StompSessionProvider } from 'react-stomp-hooks';
import { webSocketConfig } from '../../config';

const AppLayout = ({ user, features }) => {
    return (
        <PermissionsProvider>
            <StompSessionProvider
                url={CONTROL_CENTAR_WEBSOKET}
                reconnectDelay={webSocketConfig.reconnectDelay}
                connectionTimeout={webSocketConfig.connectionTimeout}
                heartbeatIncoming={webSocketConfig.heartbeatIncoming}
                heartbeatOutgoing={webSocketConfig.heartbeatOutgoing}
            >
                {user && <NavigationDrawer user={user} features={features} />}
                <Suspense fallback={<CircularLoader />}>
                    <Outlet />
                </Suspense>
            </StompSessionProvider>
        </PermissionsProvider>
    );
};

AppLayout.propTypes = {
    user: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
};

export default AppLayout;
