import { styled, IconButton, DialogTitle, DialogContent } from '@mui/material';

const CustomTitle = styled(DialogTitle)({
    paddingRight: '2rem',
    color: 'var(--color-white)',
    backgroundColor: 'var(--color-blue)',
});

const CloseIconButton = styled(IconButton)({
    zIndex: 2,
    position: 'absolute',
    right: '0.5rem',
    top: '0.5rem',
    color: 'var(--color-white)',
});

const CustomBody = styled(DialogContent)(({ sx }) => ({
    flexDirection: 'column',
    maxWidth: '80rem',
    maxHeight: '80vh',
    overflowY: 'auto',
    ...sx,
}));

export { CustomTitle, CloseIconButton, CustomBody };
