import { SUMMARY_ACTION, YARD_CHECK_ITEM_TYPE } from 'utils/constants';

export const initialYardCheckDropdownAction = (status) => {
    const { MISMATCHED, NEW, MATCHED } = YARD_CHECK_ITEM_TYPE;
    switch (status) {
        case MISMATCHED:
            return SUMMARY_ACTION.REMOVED;
        case NEW:
            return SUMMARY_ACTION.ADD;
        case MATCHED:
            return SUMMARY_ACTION.UPDATE;
        default:
            return SUMMARY_ACTION.SKIP;
    }
};
