import { createSelector } from '@reduxjs/toolkit';

export const selectPeopleAuthorizedListSlice = (state) => state.peopleAuthorizedList;
export const selectAllLocationsLookup = createSelector([selectPeopleAuthorizedListSlice], (slice) => {
    const dataSource = slice.allLocations;
    return { displayExpr: 'yardName', dataSource };
});

export const selectSelectedLocation = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.selectedLocation);

export const selectAuthoriziedPeopleList = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.authorizedPeople);

export const selectAuthoriziedfetching = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.submittingFormInProgress);

export const selectAuthoriziedPersonErrors = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.submittingFormError);

export const selectSelectedAuthoriziedPerson = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.selectedAuthorizedPerson);

export const selectAuthoriziedPersonAlerts = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.authorizedPersonAlerts);
export const selectSelectedAuthoriziedPersonAlert = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.selectedPersonAlert);

export const selectAuthoriziedPeopleFormStatus = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.formStatus);
export const selectAuthTypeFormstatus = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.authTypeFormstatus);

export const selectBeforeEditData = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.beforeEditData);
export const selectBeforeEditDataAuthType = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.beforeEditDataAuthType);

export const selectRemovePopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.removePopupVisible);
export const selectAuthTypePopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.authTypePopupVisible);
export const selectRemoveAuthTypePopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.removeAuthTypePopupVisible);
export const selectAddAuthTypePopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.addAuthTypePopupVisible);

export const selectAddPopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.addPopupVisible);

export const selectPersonList = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.personList);
export const selectSelectedPerson = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.selectedPerson);

export const selectAuthTypeList = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.authTypeList);
export const selectSelectedAuthType = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.selectedAuthType);
export const selectAddedCompany = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.addedCompany);
export const selectAddingPersonInProgress = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.addingPersonInProgress);
export const selectPopupVisible = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.popupVisible);

export const selectSearchText = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.searchText);
export const selectFilterAuthTypes = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.filterAuthTypes);
export const selectPeopleSubmittingFormInProgress = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.submittingFormInProgress);
export const selectFetchingPersonInprogress = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.fetchingPersonInprogress);
export const selectIsFetchingAuthorizedPeople = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.isFetchingAuthorizedPeople);

export const submittingFormSuccess = createSelector([selectPeopleAuthorizedListSlice], (slice) => slice.submittingFormSuccess);
